import styled from "styled-components";
import "../../styles/styled/style_variables";
import {
  primary_color,
  primary_color_darker,
} from "../../styles/styled/style_variables";

export const ProductTitle = styled.h1`
  font-size: 34px;
  font-weight: 700;
  letter-spacing: 0px;
  font-family: "karla";
  letter-spacing: -1px;
  margin-bottom: 0px;
`;

export const ProductPrice = styled.div`
  font-size: ${(props) => (props.strike ? "20px" : "34px")};
  text-decoration: ${(props) => (props.strike ? "line-through" : "none")};
  opacity: ${(props) => (props.strike ? "0.4" : "1")};
  font-weight: ${(props) => (props.strike ? "300" : "500")};
  letter-spacing: -2px;
`;

export const ProductDescription = styled.p`
  font-size: 16px;
`;

export const ProductButton = styled.button`
  padding: 5px;
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  /* min-width: 120px; */
  padding: 6px 24px;
  font-size: 16px;
  text-transform: capitalize;
  font-family: "karla";
  transition: all 0.3s ease;
  background-color: ${(props) =>
    props.outline ? `transparent` : `${primary_color}`};
  border: ${(props) => (props.outline ? `1px solid ${primary_color}` : "none")};
  color: ${(props) => (props.outline ? `${primary_color}` : "white")};
  border-radius: 100px;
  &:hover {
    cursor: pointer;
    color: ${(props) => (props.outline ? `${primary_color}` : "white")};
    background-color: ${(props) =>
      props.outline ? `white` : `${primary_color}`};
    box-shadow: 0px 4px 24px rgba(87, 88, 187, 0.2);
  }
`;

export const SliderWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  border: 1px solid #eee;
  border-radius: 4px;
  max-height: 300px !important;
  overflow: hidden;
`;

export const SliderWrapper2 = styled.div`
  width: 100%;
  /* overflow: hidden; */
  border: 1px solid #eee;
  border-radius: 4px;
  max-height: 70px !important;
  overflow: hidden;
`;

export const Slide = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  img {
    max-height: 400px;
    min-width: 100%;
    object-fit: contain;
  }
`;

export const Slide2 = styled.div`
  width: 100%;
  max-height: 80px;
  margin-right: 30px;
  position: relative;
  img {
    max-height: 100px;
    min-width: 100%;
    object-fit: cover;
  }
`;
