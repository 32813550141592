import {
  HomeOutlined,
  ShoppingOutlined,
  TagsOutlined,
  UserOutlined,
} from "@ant-design/icons";
import React from "react";

import { Route, Link, Routes, useLocation } from "react-router-dom";

function StickyFooter() {
  const location = useLocation();
  return (
    <div>
      <div>
        {/* <!-- <section id="bottom-navigation" className="md:hidden block fixed inset-x-0 bottom-0 z-10 bg-white shadow"> // if shown only tablet/mobile--> */}
        <section
          id="bottom-navigation"
          className="block border-t-2 fixed inset-x-0 bottom-0 z-10 bg-white  "
        >
          <div id="tabs" className="flex justify-between">
            <Link
              to="/"
              className={`w-full justify-center inline-block text-center py-2 pb-3 ${
                location.pathname === "/"
                  ? "text-blue-700 font-semibold hover:text-blue-700"
                  : "text-gray-800  active:text-gray-800 hover:text-gray-800"
              }`}
            >
              <HomeOutlined />
              <span className="tab tab-home block text-xs">Home</span>
            </Link>
            <Link
              to="/category"
              className={`w-full justify-center inline-block text-center py-2 pb-3  ${
                location.pathname === "/category"
                  ? "text-blue-700 font-semibold hover:text-blue-700"
                  : "text-gray-800  active:text-gray-800 hover:text-gray-800"
              }`}
            >
              <TagsOutlined />
              <span className="tab tab-kategori block text-xs">Category</span>
            </Link>

            <Link
              to="/products"
              className={`w-full  justify-center inline-block text-center py-2 pb-3  ${
                location.pathname === "/products"
                  ? "text-blue-700 font-semibold hover:text-blue-700"
                  : "text-gray-800  active:text-gray-800 hover:text-gray-800"
              }`}
            >
              <ShoppingOutlined />
              <span className="tab tab-whishlist block text-xs">Products</span>
            </Link>
            <Link
              to="/account"
              className={`w-full justify-center inline-block text-center py-2 pb-3 ${
                location.pathname === "/account"
                  ? "text-blue-700 font-semibold hover:text-blue-700"
                  : "text-gray-800  active:text-gray-800 hover:text-gray-800"
              }`}
            >
              <UserOutlined />
              <span className="tab tab-account block text-xs">Account</span>
            </Link>
          </div>
        </section>
      </div>
    </div>
  );
}

export default StickyFooter;
