import { Navigate, Outlet } from 'react-router-dom';

export const useAuth = () => {
  const token = localStorage.getItem('token') ? true : false;
  const user = localStorage.getItem('user') ? true : false;
  return token && user;
};

export const ProtectedRoutes = () => {
  const isAuth = useAuth();
  return isAuth ? <Outlet /> : <Navigate to="login" />;
};
