import { StarFilled } from '@ant-design/icons';

function Star(props) {
  let colors = {
    danger: 'bg-red-100',
    warning: 'bg-amber-100',
    success: 'bg-green-100',
  };

  let color = () => {
    if (props.count < 2) {
      return colors.danger;
    } else if (props.count >= 2 && props.count < 3.5) {
      return colors.warning;
    } else if (props.count > 3.5) {
      return colors.success;
    }
  };

  return (
    <div
      className={`badge ${color()} text-xs text-green-900 
       items-center inline-flex px-2 py-1 rounded`}
    >
      <StarFilled className="mr-1" /> {props.count}
    </div>
  );
}

export default Star;
