import { Col, Row } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const CategoryCard = ({ title, link, image }) => {
  const navigate = useNavigate();
  return (
    <div
      className="border border-sky-200 rounded-md  first-letter:hover:bg-sky-100 cursor-pointer 
      mb-4 transition-all hover:bg-sky-100
      overflow-hidden"
      onClick={() => {
        navigate(link);
      }}
    >
      {/* <div className="flex flex-nowrap  lg:flex-nowrap "> */}
      <Row justify="start" align="middle">
        <Col span={8}>
          <div className="image  mr-2 opacity-90 overflow-hidden relative">
            <img
              src={image}
              className="w-20 lg:w-40 h-full min-h-0 max-h-24 object-cover"
              alt="appliances"
            />
          </div>
        </Col>
        <Col span={16}>
          <div className="text-left p-3">
            <h6 className="text-sky-900 text-lg mb-1 font-medium truncate">
              {title}
            </h6>
            {/* <p className="text-sky-800 opacity-60">
            {e.description}
          </p> */}
            <div className="flex items-center opacity-80 mt-4">
              <h5 className="my-0 mr-2 text-sky-900 font-bold">Explore</h5>
              <img
                src="https://img.icons8.com/pastel-glyph/64/0c4a6e/move-right.png"
                width={30}
                alt=""
              />
            </div>
          </div>
        </Col>
      </Row>
      {/* </div> */}
    </div>
  );
};

export default CategoryCard;

