import {
  BookOutlined,
  DropboxOutlined,
  TagsOutlined,
  UserOutlined,
  WalletOutlined,
} from "@ant-design/icons";
import { Col, Grid, Row, Tabs } from "antd";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import PageBreadcrumb from "../../components/PageBreadcrumb";
import PageHeader from "../../components/PageHeader";
import { logout } from "../../services/auth.service";
import AccountAddress from "./AccountAddress";
import Orders from "./Orders";
import UserDetails from "./UserDetails";

const { TabPane } = Tabs;

function UserAccount() {
  const navigate = useNavigate();
  const { useBreakpoint } = Grid;
  const screen = useBreakpoint();
  console.log(screen);
  return (
    <>
      <div className="flex justify-between align-middle">
        <h1 className="text-3xl mb-3 mt-3 font-semibold">My Account</h1>
        <div className=" sm:hidden my-auto ">
          <Button
            onClick={() => {
              console.log("clicked");
              logout();
              navigate("/login");
            }}
          >
            Logout
          </Button>
        </div>
      </div>
      <PageBreadcrumb
        items={[
          { link: "/", title: "Home" },
          { link: "/account", title: "Account" },
        ]}
      />

      <Row className="mt-5">
        <Col span={24}>
          <div className="account-tabs">
            <Tabs
              defaultActiveKey="1"
              tabPosition={
                (screen.xs || screen.sm) && !screen.md ? "top" : "left"
              }
            >
              <TabPane
                tab={
                  <div className="items-center  flex">
                    <UserOutlined /> My Account
                  </div>
                }
                key="1"
              >
                <UserDetails />
              </TabPane>
              <TabPane
                tab={
                  <div className="items-center  flex">
                    <DropboxOutlined /> Orders
                  </div>
                }
                key="2"
              >
                <Orders />
              </TabPane>
              <TabPane
                tab={
                  <div className="items-center  flex">
                    <BookOutlined /> Manage Addresses
                  </div>
                }
                key="3"
              >
                <AccountAddress />
              </TabPane>
              {/* <TabPane
                tab={
                  <div className="items-center flex">
                    <TagsOutlined /> Coupons
                  </div>
                }
                key="4"
              ></TabPane> */}
              {/* <TabPane
                tab={
                  <div className="items-center flex">
                    <WalletOutlined /> Wallet
                  </div>
                }
                key="5"
              >
                <Wallet />
              </TabPane> */}
            </Tabs>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default UserAccount;
