import { Player } from '@lottiefiles/react-lottie-player';
import { Button, Result } from 'antd';
import React from 'react';

function NotFound() {
  return (
    <div className="text-center">
      <Player
        style={{ height: '600px', width: '600px' }}
        autoplay
        loop={true}
        speed="1"
        src="https://assets10.lottiefiles.com/private_files/lf30_bdec2jzh.json"
      />
      <h5 className="text-center mb-5 text-lg">Sorry, the page you visited does not exist.</h5>
      <Button type="primary" href="/">
        Back to Home
      </Button>
    </div>
  );
}

export default NotFound;
