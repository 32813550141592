import { StarFilled, UserOutlined } from '@ant-design/icons';
import Star from './Star';

function Review(props) {
  return (
    <>
      <div className="review p-4 shadow  border border-slate-100 rounded-sm mb-4">
        <div className="flex items-center text-left">
          <Star count={props.star} />
          <p className="ml-3 mb-0 font-semibold text-md"> {props.title} </p>
        </div>
        <p className="mt-2">{props.description}</p>
        <div className="flex items-center text-left mt-4 text-slate-500" style={{ fontSize: 13 }}>
          <UserOutlined />
          <p className="ml-2 mb-0"> {props.author} </p>
        </div>
      </div>
    </>
  );
}

export default Review;
