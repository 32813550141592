import React from "react";
import { Form, Input } from "antd";
import { api_forgot_password, api_login } from "../../services/auth.service";
import { createAlert } from "../../components/Notification";
import { useNavigate } from "react-router-dom";

function ForgetPassword() {
  let navigate = useNavigate();
  const handleFormSuccess = (values) => {
    // alert(1);
    api_forgot_password({
      username: values.username,
      role: "customer",
    }).then((res) => {
      console.log(res);
      if (res.data.success) {
        createAlert("success", res.data.message);
      } else {
        createAlert("error", res.data.message);
      }
    });
  };

  const handleFormError = (params) => {};

  return (
    <>
      <section className="h-screen">
        <div className="px-6 text-gray-800">
          <div className="container">
            <div className="flex xl:justify-center lg:justify-between justify-center items-center flex-wrap h-full g-6">
              <div className="grow-0 shrink-1 md:shrink-0 basis-auto xl:w-6/12 lg:w-6/12 md:w-9/12 mb-12 md:mb-0 hidden sm:block">
                <img
                  src="https://img.freepik.com/free-vector/forgot-password-concept-illustration_114360-1123.jpg?w=996&t=st=1670078263~exp=1670078863~hmac=5f08a30b45edbf4b950fc7844588b6f1658547df290878098e12adb9fc2013d2"
                  className="w-full"
                  alt="Sample"
                />
              </div>
              <div className="xl:ml-20 xl:w-5/12 lg:w-5/12 md:w-8/12 mb-12 md:mb-0">
                <h1 className="text-xl font-bold sen mb-8 block">DZone22.</h1>
                <div className="flex flex-row items-center justify-center lg:justify-start">
                  <h1 className="text-4xl font-extrabold sen mb-8">
                    Forgot Password
                  </h1>
                </div>

                <Form
                  name="basic"
                  layout="vertical"
                  initialValues={{ remember: true }}
                  onFinish={handleFormSuccess}
                  onFinishFailed={handleFormError}
                  autoComplete="off"
                >
                  <Form.Item
                    className="hide-astricks"
                    label="Mobile number"
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Please enter mobile.",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Your Registered Mobile Number" />
                  </Form.Item>

                  <Form.Item>
                    <div className="text-center lg:text-left">
                      <button
                        type="submit"
                        className="w-100 mb-3 px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                      >
                        Submit
                      </button>
                    </div>
                  </Form.Item>
                </Form>

                <div className="flex justify-between items-center mb-6">
                  <p className="text-sm font-semibold mt-2 pt-1 mb-0">
                    Remember your password ?
                    <span
                      onClick={() => {
                        navigate("/login");
                      }}
                      className="text-blue-600 hover:text-blue-700 focus:text-blue-700 transition duration-200 ease-in-out ml-1 cursor-pointer hover:cursor-pointer"
                    >
                      Login
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ForgetPassword;
