import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api, cart } from "../services/website.service";

export const getCartItems = createAsyncThunk(
  //action type string
  "counter/getCartItems",
  // callback function
  async (thunkAPI) => {
    const res = await cart.get().then((res) => {
      console.log(res.data.cartitem.length);
      console.log(res.data);
      return res.data.cartitem.length;
    });
    return res;
  }
);

export const counterSlice = createSlice({
  name: "counter",
  initialState: {
    value: 0,
  },
  reducers: {},
  extraReducers: {
    [getCartItems.fulfilled]: (state, { payload }) => {
      state.value = payload;
    },
  },
});

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectCount = (state) => state.counter.value;

export default counterSlice.reducer;
