import {
  FilterFilled,
  LoadingOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Input,
  Modal,
  Row,
  Slider,
  Spin,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useSearchParams } from "react-router-dom";
import { createAlert } from "../../components/Notification";
import PageBreadcrumb from "../../components/PageBreadcrumb";
import Product from "../../components/Product";
import ProductMobile from "../../components/ProductMobile";

import { api, cart, home } from "../../services/website.service";
import { getCartItems } from "../../store/cartSlice";
import { app_url } from "../../utilities/config";

function CategorizedProducts() {
  const navigate = useNavigate();
  // States
  const [products, setProducts] = useState();
  const [order, setOrder] = useState("price");
  const [sort, setSort] = useState("a");
  const [displayProducts, setDisplayProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [search, setSearch] = useState("");
  const [categories, setCategories] = useState();
  const [subCategories, setSubCategories] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useDispatch();
  const isMobileDevice = !useMediaQuery({
    query: "(min-device-width: 550px)",
  });

  //
  const [selectedCategory, setselectedCategory] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [categoryName, setCategoryName] = useState("");

  const category = searchParams.get("id");
  const pageSize = 6;

  const { Panel } = Collapse;

  // End - States

  const getData = useCallback(async () => {
    setLoading(true);
    setPage(1);
    console.log(order);
    console.log(subCategories);
    await home
      .getCategorizedProducts(
        page,
        pageSize,
        search,
        order,
        category,
        subCategories
      )
      .then((res) => {
        console.log(res);
        setProducts(res.data);
        setDisplayProducts(res.data);
      });
    // Fetching categories
    !categories &&
      (await api
        .fetch_product_categories()
        .then((res) => {
          let cat_resp = res.data.data.filter((x) => x.id == category)[0];
          setCategoryName(cat_resp.title);
          setCategories(cat_resp.sub_category);
          // console.log(res.data.data.filter((x) => x.id == category)[0].sub_category);
        })
        .catch((err) => {
          console.error("Error while fetching categories ->", err.message);
        }));
    setLoading(false);
  }, [order, subCategories]);

  const getSearchResults = useCallback(async () => {
    setLoading(true);
    setPage(1);
    await home.getProducts(1, pageSize, search).then((res) => {
      console.log(res.data);
      setProducts(res.data);
      setDisplayProducts(res.data);
    });
    setLoading(false);
  }, [search]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleCategoryChange = (e) => {
    console.log(e);
  };

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const addToCart = async (id) => {
    await cart
      .add({
        product_id: id,
        quantity: 1,
      })
      .then((res) => {
        console.log(res);

        if (res.success) {
          dispatch(getCartItems());
          createAlert("success", "Item successfully added to cart.");
        } else {
          if (res.code === 401) {
            navigate("/login");
            createAlert("warning", "You have to login to add items to cart.");
          } else {
            createAlert("error", res.message);
          }
        }
      });
  };

  // Filter

  const filterProducts = (params) => {
    let filtered;
    if (params.cod) {
      filtered = products.filter((e) => {
        if (e.cod === params.cod) {
          return e;
        }
      });
      setDisplayProducts(filtered);
    } else {
      setDisplayProducts(products);
    }
    console.log(filtered);
  };

  // Fetch Data
  // console.log(categories);
  const fetchData = async () => {
    setLoading(true);
    await home.getProducts(page, pageSize, search,
      order,
      category,
      subCategories).then((res) => {
      console.log(res);
      setProducts(res.data);
      if (res.success) {
        let oldProducts = displayProducts;
        let newProducts = [...oldProducts, ...res.data];
        setDisplayProducts(newProducts);
        setPage(page + 1);
      } else {
        setHasMore(false);
      }
    });
    setLoading(false);
  };

  // Range slider
  const marks = {
    0: "0",
    50: "50K",
  };
  const formatter = (value) => `${value * 1000}`;
  const closeModal = () => setIsModalVisible(false);

  return (
    <>
      {/* <Modal
        title="Filters"
        footer={false}
        visible={isModalVisible}
        onCancel={closeModal}
      >
        {Filter()}
        
      </Modal> */}

      <h1 className="text-3xl mb-3 mt-3 font-semibold">{categoryName}</h1>
      <PageBreadcrumb
        items={[
          { link: "/", title: "Home" },
          { title: "Category", link: "/category" },
          { title: categoryName },
        ]}
      />

      <h5 className="font-bold text-lg mt-10 mb-4"> Sub Categories </h5>
      <div className="flex mb-10 overflow-x-scroll">
        {categories &&
          categories.map((e) => {
            return (
              <div className="flex mr-4" key={e.title}>
                <div
                  onClick={() => {
                    if (subCategories === e.id) setSubCategories(null);
                    else setSubCategories(e.id);
                  }}
                  className={`py-2 px-5 rounded-full  items-center first-letter:hover:bg-sky-100 transition cursor-pointer mb-4 ${
                    subCategories === e.id ? "bg-sky-200" : "bg-sky-50"
                  } `}
                >
                  <h6 className="text-sky-900 mb-0 truncate capitalize text-center">
                    {e.title}
                  </h6>
                </div>
              </div>
            );
          })}
      </div>

      {/*  */}

      <Row className="mt-10" gutter={30}>
        {/* Filters */}
        {/* <Col xs={0} lg={6}> */}
        {/* <h5 className="font-semibold text-lg mb-4"> Filters </h5> */}
        {/* <div className="filters mt-4">
              <div className="filter-box">
                <h5 className="font-medium text-md mb-0 bg-slate-100 p-4">
                  Sub - Categories
                </h5>
                <div className="filter-header border border-slate-100 px-5 py-4 border-t-0">
                  {subCategories.map((e) => {
                    return (
                      <div className="checkboxes flex mb-3" key={e.id}>
                        <Checkbox
                          value={e.id}
                          onChange={(e) => {
                            console.log(e.target.checked);
                          }}
                        >
                          {e.title}
                        </Checkbox>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div> */}
        {/*  */}
        {/* {Filter()} */}
        {/* </Col> */}
        {/* <Col lg={18} xs={24} sm={24}> */}
        <Col>
          {/* Sort */}
          <div className="sort-area">
            <div className="flex sorters border-b justify-between sm:justify-start border-solid border-slate-200">
              <h5 className="font-semibold text-md mb-0 mr-4"> Sort By </h5>
              <div className="sorts flex">
                <span
                  className={`cursor-pointer mr-4 pb-1 ${
                    sort === "a"
                      ? "text-blue-400 border-b truncate border-solid border-blue-400"
                      : "text-slate-400 truncate"
                  }`}
                  onClick={() => {
                    setPage(1);
                    setOrder("price");
                    setSort("a");
                  }}
                >
                  Price Low to High
                </span>
                <span
                  className={`cursor-pointer mr-4 pb-1 ${
                    sort === "b"
                      ? "text-blue-400 border-b truncate border-solid border-blue-400"
                      : "text-slate-400 truncate"
                  }`}
                  onClick={() => {
                    setPage(1);
                    setOrder("-price");
                    setSort("b");
                  }}
                >
                  Price High to Low
                </span>
              </div>
            </div>
          </div>
          {/* Ends - Sort */}
          {/* Search */}

          {/* Ends - Search */}
          {/* -------------- */}
          {/* Products */}
          <div className="mt-8 flex">
            <Input
              className="custom-search"
              placeholder="Seach amoung hundereds of products...."
              prefix={<SearchOutlined />}
              onChange={(e) => {
                if (e.target.value.length > 0) {
                  setSearch(e.target.value);
                  getSearchResults();
                } else {
                  setSearch("");
                  setPage(1);
                  setHasMore(true);
                  getData();
                }
              }}
            />
            {/* <div className=" lg:hidden">
              <Button
                className="ml-3 mt-1"
                shape="circle"
                onClick={() => setIsModalVisible(true)}
                icon={<FilterFilled />}
              />
            </div> */}
          </div>

          {/* Infinite scroll */}
          {displayProducts ? (
            <InfiniteScroll
              dataLength={displayProducts.length} //This is important field to render the next data
              next={fetchData}
              hasMore={hasMore}
              loader={
                <Spin
                  indicator={antIcon}
                  spinning={loading}
                  tip="Loading Products..."
                ></Spin>
              }
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <b>You have seen all the products!</b>
                </p>
              }
            >
              <Row gutter={20} className="mt-10">
                {displayProducts &&
                  displayProducts.map((e, index) => {
                    if (isMobileDevice)
                      return (
                        <Col sm={12} xs={12} md={12} xxl={8} key={index}>
                          <ProductMobile
                            assured={e.assured}
                            variant="small"
                            id={e.id}
                            title={e.title}
                            shop={e.shop_name}
                            mrp={e.mrp}
                            star={e.review}
                            price={e.price}
                            description={e.description}
                            category={e.category}
                            cartClick={() => {
                              addToCart(e.id);
                            }}
                            image={`${
                              e.thumbnail
                                ? `${app_url}${e.thumbnail}`
                                : "https://img.freepik.com/free-psd/packaging-box-mock-up_23-2148698793.jpg?w=2000"
                            }`}
                          />
                        </Col>
                      );
                    else
                      return (
                        <Col sm={24} xs={24} md={12} xxl={8} key={index}>
                          <Product
                            assured={e.assured}
                            variant="small"
                            id={e.id}
                            title={e.title}
                            shop={e.shop_name}
                            mrp={e.mrp}
                            star={e.review}
                            price={e.price}
                            description={e.description}
                            category={e.category}
                            cartClick={() => {
                              addToCart(e.id);
                            }}
                            image={`${
                              e.thumbnail
                                ? `${app_url}${e.thumbnail}`
                                : "https://img.freepik.com/free-psd/packaging-box-mock-up_23-2148698793.jpg?w=2000"
                            }`}
                          />
                        </Col>
                      );
                  })}
              </Row>
            </InfiniteScroll>
          ) : (
            ""
          )}

          {/* Ends - infinite scroll  */}

          {/* Ends -- Products */}
        </Col>
      </Row>
    </>
  );

  function Filter() {
    return (
      <Collapse defaultActiveKey={1} onChange={() => {}}>
        {categories &&
          categories.map((e) => {
            return (
              <Panel className="capitalize" header={e.title} key={e.id}>
                {e.sub_category.map((e) => {
                  return (
                    <div className="checkboxes flex mb-3" key={e.id}>
                      <Checkbox
                        value={e.id}
                        onChange={(e) => {
                          if (e.target.checked) {
                            //   setPage(1);
                            // filter_by_category(e.target.value);
                          }
                        }}
                      >
                        {e.title}
                      </Checkbox>
                    </div>
                  );
                })}
              </Panel>
            );
          })}
        {/* <Panel header="This is panel header 1" key="1">
            <p></p>
          </Panel>
          <Panel header="This is panel header 2" key="2">
            <p></p>
          </Panel>
          <Panel header="This is panel header 3" key="3">
            <p></p>
          </Panel> */}
      </Collapse>
    );
  }
}

export default CategorizedProducts;
