import HeaderMobile from "./layouts/HeaderMobile";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "./layouts/Footer";
import { useEffect, useState } from "react";
import { getCartItems } from "./store/cartSlice";
import { useDispatch } from "react-redux";
import OneSignal from "react-onesignal";

import { Layout } from "antd";
import StickyFooter from "./layouts/StickyFooter";
import { useMediaQuery } from "react-responsive";
import Header from "./layouts/Header";
import ScrollToTop from "react-scroll-to-top";
import { ArrowUpOutlined, UpOutlined } from "@ant-design/icons";
import { regInBackend, subscribePush } from "./services/website.service";

import { useNavigate } from "react-router-dom";

import { Capacitor } from "@capacitor/core";
import { App as CapacitorApp } from "@capacitor/app";

function App() {
  const router = useNavigate();
  const { Content, Sider } = Layout;
  const dispatch = useDispatch();
  const [initialized, setInitialized] = useState(false);
  const location = useLocation();

  const OneSignalInit = () => {
    window["plugins"].OneSignal.setAppId(
      "45c35f85-adee-4f3d-8b58-122df1e47ef8"
    );
    window["plugins"].OneSignal.setNotificationOpenedHandler(function (
      jsonData
    ) {
      console.log("notificationOpenedCallback: " + JSON.stringify(jsonData));
    });

    window["plugins"].OneSignal.promptForPushNotificationsWithUserResponse(
      function (accepted) {
        console.log("User accepted notifications: " + accepted);
      }
    );
  };

  useEffect(() => {
    dispatch(getCartItems());

    if (Capacitor.isNativePlatform()) {
      OneSignalInit();
      window["plugins"].OneSignal.getDeviceState(async (state) => {
        console.log(state.userId);
        await regInBackend(state.userId).then((res) => {
          console.log("push token response", res);
        });
      });
    } else {
      OneSignal.init({
        appId: "45c35f85-adee-4f3d-8b58-122df1e47ef8",
        allowLocalhostAsSecureOrigin: true,
      }).then(() => {
        OneSignal.showSlidedownPrompt().then(() => {
          OneSignal.getUserId(async (e) => {
            localStorage.setItem("device_token", e);
            console.log("device_token", e);
            await regInBackend(e).then((res) => {
              console.log("push token responsve", res);
            });
          }).catch((e) => {
            console.log(e);
          });
          console.log("initialised");
        });
      });
    }
  }, []);

  useEffect(() => {
    CapacitorApp.addListener("backButton", ({ canGoBack }) => {
      if (!canGoBack) {
        CapacitorApp.exitApp();
      } else {
        console.log("hey");
        window.history.back();
      }
    });
  }, []);

  const isMobileDevice = !useMediaQuery({
    query: "(min-device-width: 550px)",
  });

  console.log(isMobileDevice);

  return (
    // <Layout>

    <>
      {isMobileDevice && <StickyFooter />}
      {isMobileDevice ? <HeaderMobile /> : <Header />}

      <div
        className="App flex flex-col"
        style={{ minHeight: "100vh", overflow: "hidden" }}
      >
        <ScrollToTop
          className="mb-10 bg-white-500 rounded-lg shadow-xl"
          style={{ borderRadius: 50 }}
          smooth
          top="900"
          component={
            <UpOutlined style={{ color: "black" }} className="mb-1 text-lg " />
          }
        />
        {/* {isMobileDevice ? <HeaderMobile /> : <Header />} */}
        <div
          className={`container mx-auto px-4 ${
            isMobileDevice ? "my-20" : ""
          } m lg:px-6 flex-1`}
        >
          <Outlet />
        </div>
        {!isMobileDevice && (
          <div>
            <Footer />
          </div>
        )}
      </div>
      {/* <StickyFooter /> */}
    </>
    //  </Layout>
  );
}

export default App;
