// antd
import {
  GiftOutlined,
  PlusOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import { Alert, Card, Col, Image, Input, Row, Select } from "antd";
import React, { useEffect, useState } from "react";

import PageBreadcrumb from "../../components/PageBreadcrumb";
import Product from "../../components/Product";
import Review from "../../components/Review";
import { cart, home, review } from "../../services/website.service";

import { Skeleton } from "antd";
import { useSearchParams } from "react-router-dom";
//

import AppButton from "../../components/AppButton";
import { createAlert } from "../../components/Notification";
import {
  Slide,
  Slide2,
  SliderWrapper,
  SliderWrapper2,
} from "./ProductView_styles";
import Star from "../../components/Star";
import { app_url } from "../../utilities/config";
import { getCartItems } from "../../store/cartSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import ProductMobile from "../../components/ProductMobile";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const { Option } = Select;

function ProductView() {
  const dispatch = useDispatch();
  const [product, setProduct] = useState();
  const [specifications, setSpecifications] = useState();
  const [productImages, setProductImages] = useState();
  const [qty, setQty] = useState();
  const [loading, setLoading] = useState();
  const [products, setProducts] = useState();
  const navigate = useNavigate();
  const isMobileDevice = !useMediaQuery({
    query: "(min-device-width: 550px)",
  });
  // product slider
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  const slider = React.useRef(null);

  const sliderSettings = {
    arrows: true,
    dots: false,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplaySpeed: 5000,
    useCss: true,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1043,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settings = {
    asNavFor: nav2,
    ref: (slider1) => setNav1(slider1),
    arrows: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    useCss: true,
    // variableWidth: true
  };

  const settings2 = {
    variableWidth: true,
    asNavFor: nav1,
    ref: (slider2) => setNav2(slider2),
    focusOnSelect: true,
    arrows: true,
    dots: true,
    infinite: true,
    // autoplay: true,
    slidesToShow: productImages?.length && !isMobileDevice > 3 ? 3 : 2,
    swipeToSlide: true,
    slidesToScroll: 1,
    useCss: true,
    // variableWidth: true
  };

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setLoading(true);
    const getData = async () => {
      home.getSingleProduct(searchParams.get("id")).then((res) => {
        setLoading(false);
        console.log(res);
        setProduct(res.data);
        if (res.data.specification) {
          let specData = JSON.parse(res.data.specification).specification;
          let SpecsWithDimensions = [
            ...specData,
            {
              title: "Dimensions",
              description: `${res.data.length} x ${res.data.breadth} x ${res.data.hiegth}`,
            },
            {
              title: "Weight",
              description: `${res.data.weigth} gms`,
            },
          ];
          setSpecifications(SpecsWithDimensions);
        }
        setProductImages(res.data.product_image);
      });
      home.get_products("latest").then((res) => {
        console.log(res.data);
        setProducts(res.data.slice(0, 5));
      });
    };
    getData();
    // getReviews();
  }, [searchParams]);

  console.log(specifications);

  const addToCart = async (id) => {
    await cart
      .add({
        product_id: id,
        quantity: 1,
      })
      .then((res) => {
        console.log(res);
        if (res.success) {
          createAlert("success", "Item successfully added to cart.");
        } else {
          if (res.code === 401) {
            navigate("/login");
            createAlert("warning", "You have to login to add items to cart.");
          } else {
            createAlert("error", res.message);
          }
        }
      });
  };

  return (
    <>
      <div className="mt-5"></div>
      <PageBreadcrumb
        items={[
          { link: "/", title: "Home" },
          { link: "/", title: "Products" },
          { title: product?.title },
        ]}
      />

      {product && !loading ? (
        <>
          <Row gutter={40} className="mt-3">
            {/* Product images */}
            <Col xs={24} lg={12}>
              <SliderWrapper>
                <Slider {...settings}>
                  {productImages &&
                    productImages.map((e) => {
                      return (
                        <Slide key={e.id}>
                          <Image
                            className="flex justify-center"
                            style={{ height: "300px" }}
                            src={`${app_url + e.image}`}
                            alt=""
                          />
                        </Slide>
                      );
                    })}
                </Slider>
              </SliderWrapper>
              <SliderWrapper2 className="mt-5 ">
                {productImages.length > 1 ? (
                  <Slider {...settings2}>
                    {productImages?.map((e) => {
                      console.log(e);
                      return (
                        <Slide2 key={e.id}>
                          <img
                            style={{ objectFit: "contain" }}
                            src={`${app_url + e.image}`}
                            alt=""
                          />
                        </Slide2>
                      );
                    })}
                  </Slider>
                ) : (
                  ""
                )}
                {/* <div className="flex">
                  {productImages?.map((e) => {
                    return (
                      <div className="image-box flex">
                        <img src={`${app_url + e.image}`} alt="" />
                      </div>
                    );
                  })}
                </div> */}
              </SliderWrapper2>
            </Col>
            {/* Product details */}
            <Col xs={24} lg={12} className="mt-5">
              <h1 className="font-medum text-2xl text-slate-800 ">
                {" "}
                {product.title}{" "}
              </h1>
              <p className="mb-3 mt-1 text-slate-600 line-clamp-3">
                {product.description}
              </p>
              <div className="flex pb-8">
                {product.review > 0 ? <Star count={product.review} /> : ""}
                {product.assured ? (
                  <span className="ml-3 badge bg-sky-700 py-1 font-medium text-white px-2 text-xs rounded-full inline-flex items-center justify-center">
                    <img
                      src="https://img.icons8.com/external-inkubators-detailed-outline-inkubators/100/ffffff/external-verified-ecommerce-user-interface-inkubators-detailed-outline-inkubators.png"
                      width={16}
                      className="mr-1"
                      alt=""
                    />
                    Assured
                  </span>
                ) : (
                  ""
                )}{" "}
                {product?.stock_qty === 0 && (
                  <span className=" badge bg-red-700 py-1 font-medium text-white px-2 text-xs rounded-full inline-flex items-center justify-center mr-3">
                    Out Of Stock!
                  </span>
                )}
                {product.return_period !== 0 ? (
                  <span class="bg-purple-100 ml-1 sm:ml-0 text-purple-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-purple-200 dark:text-purple-900">{`${product.return_period} days returns`}</span>
                ) : (
                  <span class="bg-yellow-100 ml-1 sm:ml-0 text-yellow-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-200 dark:text-yellow-900">
                    {" "}
                    No Returns
                  </span>
                )}
              </div>
              <div>
                <h1 className="flex items-center tracking-tight">
                  <span className="font-semibold text-xl">
                    ₹ {product.price.toLocaleString("en-IN")}
                  </span>
                  <span className="ml-3 line-through text-slate-400 text-sm">
                    ₹ {product.mrp.toLocaleString("en-IN")}
                  </span>
                  <span className="font-semibold text-sm text-left  ml-3 text-green-700 tracking-tighter flex items-center">
                    {(
                      ((product.mrp - product.price) / product.mrp) *
                      100
                    ).toFixed(0)}{" "}
                    % &nbsp;Discount
                  </span>
                </h1>
                {Boolean(product.stock_qty) &&
                  (product?.stock_qty > 5 ? null : product.stock_qty <= 0 ? (
                    // <span class="bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900">
                    <p className="text-green-600">Out of Stock!</p>
                  ) : (
                    <p className="text-red-800">
                      {" "}
                      {`only ${product.stock_qty} left Hurry !`}
                    </p>
                  ))}
              </div>
              <div className="flex mt-5">
                <AppButton
                  disabled={product.stock_qty < 1}
                  title="Add to Cart"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    addToCart(product.id);
                    dispatch(getCartItems());
                  }}
                />
                <AppButton
                  outline
                  onClick={() => {
                    let url = window.location.href;
                    navigator.clipboard.writeText(url);
                    createAlert("success", "Copied url to clipboard.");
                  }}
                  title="Share"
                  icon={<ShareAltOutlined />}
                />
              </div>
              {product.expiry_date && (
                <div className="mt-4 text-slate-400">
                  {" "}
                  <p>
                    Expires on :{" "}
                    <span className="text-black">
                      {"  " + product.expiry_date}
                    </span>
                  </p>
                </div>
              )}
              {/* {product?.warraty_period > -1 && ( */}
              <div className="mt-4 text-slate-400">
                {" "}
                <p>
                  Warranty :{" "}
                  <span className="text-black">
                    {product.warranty_period }
                  </span>
                </p>
              </div>
              {/* )} */}
              {/* <div className="text-left mt-3">
                <span className="text-xs">
                  Sold By :{" "}
                  <span className="text-slate-400"> {product.vendor} </span>
                </span>
              </div> */}
              {/* 
              <div className="mt-8">
                <h5 className="font-medium mb-2 text-xs">
                  {" "}
                  Check delivery Availability :
                </h5>
                <div className="text-left">
                  <Input placeholder="Enter Pincode" style={{ width: 200 }} />
                  <h6 className="mt-2 text-cyan-600">
                    {" "}
                    Assured Delivery in in 4-5 Working days.{" "}
                  </h6>
                </div>
              </div> */}
            </Col>
          </Row>
          {/* Description */}
          <Row className="mt-10">
            <Col span={24}>
              <h1 className="text-xl font-bold text-slate-700">
                {" "}
                Product Description{" "}
              </h1>
              <p className="mt-3">{product.long_description}</p>
            </Col>
          </Row>
          {/* Specifications */}

          {!loading &&
          product &&
          product.specification &&
          specifications[0].title ? (
            <>
              <h1 className="text-xl font-bold text-slate-700 mt-8">
                {" "}
                Product Specifications{" "}
              </h1>
              <div className="">
                {specifications &&
                  specifications.map((e) =>
                    e.title && e.description ? (
                      <Row className="text-left border border-solid border-slate-200">
                        <Col
                          sm={12}
                          className=" sm:border-r border-slate-200 p-2"
                        >
                          <div
                            className=" flex items-center h-full"
                            style={{ fontWeight: 700 }}
                          >
                            {e.title}
                          </div>
                        </Col>
                        <Col
                          sm={12}
                          className="sm:border-r border-slate-200 p-2"
                        >
                          <div className=" flex items-center h-full">
                            {e.description}
                          </div>
                        </Col>
                      </Row>
                    ) : null
                  )}
              </div>
            </>
          ) : (
            ""
          )}
          {/* Reviews */}
          {product.review > 0 ? (
            <Row className="mt-10">
              <Col span={24}>
                <h1 className="text-xl flex items-center text-slate-700 font-bold mb-4">
                  <span className="mr-3">Reviews</span>
                  <Star count={product.review} />
                </h1>
                {product.product_review.map((e) => {
                  if (e.verified) {
                    return (
                      <Review
                        title={e.title}
                        author={e.user}
                        star={e.star}
                        description={e.description}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
              </Col>
            </Row>
          ) : (
            ""
          )}

          <hr className="border-slate-200 mt-8" />
          {/* Related products */}

          <h1 className="text-xl font-bold my-5 text-slate-700">
            {" "}
            Related Products{" "}
          </h1>

          <div className="slider-wrapper relative w-full">
            <Slider ref={slider} {...sliderSettings} className="w-full">
              {products &&
                products.map((e) => {
                  if (isMobileDevice)
                    return (
                      <div>
                        <ProductMobile
                          variant={"small"}
                          id={e.id}
                          title={e.title}
                          shop={e.shop_name}
                          mrp={e.mrp}
                          price={e.price}
                          cartClick={() => {
                            addToCart(e.id);
                            dispatch(getCartItems());
                          }}
                          description={e.description}
                          category={e.category}
                          image={`${
                            e.thumbnail
                              ? `${app_url + e.thumbnail}`
                              : "https://img.freepik.com/free-psd/packaging-box-mock-up_23-2148698793.jpg?w=2000"
                          }`}
                        />
                      </div>
                    );

                  return (
                    <div>
                      <Product
                        id={e.id}
                        title={e.title}
                        shop={e.shop_name}
                        mrp={e.mrp}
                        price={e.price}
                        cartClick={() => {
                          addToCart(e.id);
                          dispatch(getCartItems());
                        }}
                        description={e.description}
                        category={e.category}
                        image={`${
                          e.thumbnail
                            ? `${app_url + e.thumbnail}`
                            : "https://img.freepik.com/free-psd/packaging-box-mock-up_23-2148698793.jpg?w=2000"
                        }`}
                      ></Product>
                    </div>
                  );
                })}
            </Slider>
          </div>
        </>
      ) : (
        <Skeleton className="mt-10" active />
      )}
    </>
  );
}

export default ProductView;
