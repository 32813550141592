import styled from "styled-components";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";

const Header = styled.div`
  margin-top: 10px;
  h1 {
    letter-spacing: -1px;
    font-weight: 800;
    font-size: 40px;
    margin-bottom: 10px;
  }
`;

function PageHeader(props) {
  return (
    <div className="page-header">
      <Header>
        <h1>{props.title}</h1>
      </Header>
    </div>
  );
}

export default PageHeader;
