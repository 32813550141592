import { Col, Form, Input, Radio, Row } from "antd";
import React, { useEffect, useState } from "react";
import { createAlert } from "../../components/Notification";
import { user } from "../../services/website.service";

function UserDetails() {
  const [form] = Form.useForm();
  const formRef = React.createRef();
  const [disableForm, setDisableForm] = useState(true);
  const [passwordForm, setPasswordForm] = useState(true);

  const [form2] = Form.useForm();
  const formRef2 = React.createRef();

  const handleFormSuccess = (values) => {
    console.log("success");
    user.edit_user(values).then((res) => {
      localStorage.setItem("user", JSON.stringify(res));
      if (res) {
        createAlert("success", "Details edited successfully.");
        setDisableForm(true);
      } else {
        createAlert("error", res.message);
      }
    });
  };

  const handleFormError = (params) => {
    console.log("error");
  };

  const handlePasswordSuccess = (values) => {
    let userDetails = JSON.parse(localStorage.getItem("user"));
    user
      .changePassword({
        username: userDetails.username,
        old_password: values.old_password,
        new_password: values.new_password,
      })
      .then((res) => {
        console.log(res);
        if (res.success) {
          setPasswordForm(true);
          form2.resetFields();
          createAlert("success", "Password changed succesfully");
        } else {
          createAlert("error", res.message);
        }
      });
  };

  const fillDetails = () => {
    let user = JSON.parse(localStorage.getItem("user"));
    console.log(user.username);
    form.setFieldsValue({
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      gender: user.gender,
      username: user.username,
    });
  };

  useEffect(() => {
    fillDetails();
  }, []);

  return (
    <>
      <div className="flex justify-between">
        <h3 className="text-xl font-bold mb-10"> Manage your account </h3>
        <button
          onClick={() => {
            setDisableForm(false);
          }}
          type="submit"
          className="w-100 px-7 py-2 bg-slate-500 text-white font-medium text-sm leading-snug capitalize rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out mb-0 self-start"
        >
          Edit
        </button>
      </div>

      <Form
        // className={`mt-5 ${disableForm && !props.isModalOpen && "hidden"}  `}
        className="mt-5"
        name="basic"
        layout="vertical"
        initialValues={{ remember: true }}
        onFinish={handleFormSuccess}
        disabled={disableForm}
        onFinishFailed={handleFormError}
        autoComplete="off"
        form={form}
        ref={formRef}
      >
        <Row gutter={24}>
          <Col
            // md={12} xs={24}

            span={24}
          >
            <Form.Item
              className="hide-astricks"
              label="Name"
              name="first_name"
              rules={[
                {
                  required: true,
                  message: "Please enter Name.",
                },
              ]}
            >
              <Input placeholder="Enter Name" />
            </Form.Item>
          </Col>
          {/* <Col md={12} xs={24}>
            <Form.Item
              className="hide-astricks"
              label="Last Name"
              name="last_name"
              rules={[
                {
                  required: true,
                  message: "Please enter last name.",
                },
              ]}
            >
              <Input placeholder="Enter last Name" />
            </Form.Item>
          </Col> */}

          <Col md={12} xs={24}>
            <Form.Item
              className="hide-astricks"
              label="Email address"
              name="email"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please enter name.",
                },
              ]}
            >
              <Input placeholder="Enter Name" />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              className="hide-astricks"
              label="Phone Number"
              name="username"
              hasFeedback
            >
              <Input disabled={true} placeholder="Enter Phone number" />
            </Form.Item>
          </Col>
          {/* <Col md={12} xs={24}>
            <Form.Item
              label="Gender"
              className="hide-astricks flex items-start"
              name="gender"
            >
              <Radio.Group defaultValue={"male"}>
                <Radio.Button value="male"> Male</Radio.Button>
                <Radio.Button value="female">Female</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col> */}

          <Col md={24} xs={24}>
            <Form.Item className="mb-0 mt-5">
              <div className="text-center lg:text-left">
                <button
                  type="submit"
                  className="w-100 px-7 py-2 bg-blue-500 text-white font-medium text-sm leading-snug capitalize rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out mb-0 disabled:bg-slate-400 disabled:cursor-not-allowed disabled:text-slate-300"
                  disabled={disableForm}
                >
                  Save changes
                </button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <hr className="mb-10" />
      {/* Change password */}
      <div className="flex justify-between">
        <h3 className={` ${passwordForm && "hidden"} text-xl font-bold mb-10`}>
          {" "}
          Change password{" "}
        </h3>
        <button
          onClick={() => {
            setPasswordForm(false);
          }}
          type="submit"
          className={`w-100 px-7 py-2 bg-slate-500 text-white font-medium text-sm leading-snug capitalize rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out mb-0 self-start`}
        >
          Change password
        </button>
      </div>
      <Form
        // className="mt-5"
        className={`mt-5 ${passwordForm && "hidden"}  `}
        name="password"
        disabled={passwordForm}
        form={form2}
        layout="vertical"
        initialValues={{ remember: true }}
        onFinish={handlePasswordSuccess}
        autoComplete="off"
      >
        <Row gutter={24}>
          <Col md={24} xs={24}>
            <Form.Item
              className="hide-astricks"
              label="Old password"
              name="old_password"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please enter old password.",
                },
              ]}
            >
              <Input type="password" placeholder="Enter Old Password" />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              className="hide-astricks"
              label="New password"
              name="new_password"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please enter new password.",
                },
              ]}
            >
              <Input type="password" placeholder="Enter New password" />
            </Form.Item>
          </Col>
          <Col md={12} xs={24}>
            <Form.Item
              className="hide-astricks"
              label="Confirm New password"
              name="confirm_new_password"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("new_password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "The two passwords that you entered do not match!"
                    );
                  },
                }),
              ]}
            >
              <Input type="password" placeholder="Confirm your password" />
            </Form.Item>
          </Col>
          <Col md={24}>
            <Form.Item className="mb-0">
              <div className="text-center lg:text-left">
                <button
                  type="submit"
                  className="w-100 px-7 py-2 bg-blue-500 text-white font-medium text-sm leading-snug capitalize rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out mb-0 disabled:bg-slate-400 disabled:cursor-not-allowed disabled:text-slate-300"
                  disabled={passwordForm}
                >
                  Change password
                </button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default UserDetails;
