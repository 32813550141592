import { Col, Grid, Row, Tabs } from "antd";
import PageBreadcrumb from "../../components/PageBreadcrumb";
import PageHeader from "../../components/PageHeader";
import Orders from "./Orders";

const { TabPane } = Tabs;

function MyOrders() {
  const { useBreakpoint } = Grid;
  const screen = useBreakpoint();
  console.log(screen);
  return (
    <>
      <PageHeader title="My Orders" />
      <PageBreadcrumb
        items={[{ link: "/", title: "Home" }, { title: "My Orders" }]}
      />
      <Row className="mt-10">
        <Col xs={24} lg={18}>
          <Orders />
          {/* <div className="account-tabs">
            <Tabs
              defaultActiveKey="1"
              tabPosition={
                (screen.xs || screen.sm) && !screen.md ? "top" : "left"
              }
            >
              <TabPane
                tab={
                  <div className="items-center  flex">
                    <UserOutlined /> My Account
                  </div>
                }
                key="1"
              >
                <UserDetails />
              </TabPane>
              <TabPane
                tab={
                  <div className="items-center  flex">
                    <DropboxOutlined /> Orders
                  </div>
                }
                key="2"
              >
                <Orders />
              </TabPane>
              <TabPane
                tab={
                  <div className="items-center  flex">
                    <BookOutlined /> Manage Addresses
                  </div>
                }
                key="3"
              >
                <AccountAddress />
              </TabPane>
              {/* <TabPane
                tab={
                  <div className="items-center flex">
                    <TagsOutlined /> Coupons
                  </div>
                }
                key="4"
              ></TabPane> */}
          {/* <TabPane
                tab={
                  <div className="items-center flex">
                    <WalletOutlined /> Wallet
                  </div>
                }
                key="5"
              >
                <Wallet />
              </TabPane> */}
          {/* </Tabs>
          </div> */}
        </Col>
      </Row>
    </>
  );
}

export default MyOrders;
