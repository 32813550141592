import { Alert, Col, Form, Modal, Radio, Row, Select, Spin } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import PageBreadcrumb from "../../components/PageBreadcrumb";
import PageHeader from "../../components/PageHeader";
import {
  address,
  cart,
  orders,
  payment,
  wallet,
} from "../../services/website.service";

import { createAlert } from "../../components/Notification";

// import { AnimatePresence, motion } from 'framer-motion';
import {
  ArrowRightOutlined,
  LoadingOutlined,
  PlusOutlined,
  WarningFilled,
} from "@ant-design/icons";
import { AnimatePresence, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import AccountAddress from "../account/AccountAddress";

import { Player } from "@lottiefiles/react-lottie-player";
import { useDispatch } from "react-redux";
import { getCartItems } from "../../store/cartSlice";

const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function Checkout() {
  const [formloading, setFormloading] = useState(false);
  const [addresses, setAddresses] = useState(false);
  const [loading, setLoading] = useState(false);
  const [walletBalance, setWalletBalance] = useState();
  const [cartDetails, setCartDetails] = useState();
  const [addressId, setAddressId] = useState();
  const [selectedAddress, setSelectedAddress] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [paymentType, setPaymentType] = useState();
  //
  const [checkoutSuccess, setCheckoutSuccess] = useState();

  const [productWithoutCod, setProductWithoutCod] = useState();
  const [disableCheckout, setDisableCheckout] = useState(false);
  const dispatch = useDispatch();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    getAddresses();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    getAddresses();
  };

  // const loadScript = (src) => {
  //   return new Promise((resolve) => {
  //     const script = document.createElement("script");
  //     script.src = src;
  //     script.onload = () => {
  //       resolve(true);
  //     };
  //     script.onerror = () => {
  //       resolve(false);
  //     };
  //     document.body.appendChild(script);
  //   });
  // };

  const checkCart = useCallback(async () => {
    cart.get().then((res) => {
      setCartDetails(res.data);

      let productsWithoutCOD = [];

      res.data.cartitem.forEach((e) => {
        if (e.cod === false) {
          console.log("Product without Cod", e);
          productsWithoutCOD.push(e);
        }
      });

      if (productsWithoutCOD.length) {
        setDisableCheckout(true);
        setPaymentType("cod");
      }

      setProductWithoutCod(productsWithoutCOD);
    });
  }, []);

  const getCartData = useCallback(async () => {
    await cart.get().then((res) => {
      setCartDetails(res.data);
      console.log("Cart", res.data);
    });
  }, []);

  const getAddresses = useCallback(async () => {
    await address.get().then((res) => {
      console.log(res);
      setAddresses(res.data);
      if (res.data.length > 0) {
        setSelectedAddress(res.data[0].id);
      }
    });
  }, []);

  useEffect(() => {
    setPaymentType("cod");
  }, []);

  useEffect(() => {
    getAddresses();

    getCartData();
    checkCart();
  }, [getCartData, getAddresses, checkCart]);

  const checkout = () => {
    if (selectedAddress) {
      createOrder();
    } else {
      createAlert("error", "Please select the addresss.");
    }
    dispatch(getCartItems());
  };

  const createOrder = (params) => {
    setLoading(true);
    orders
      .create({
        payment_type: paymentType,
        address_id: selectedAddress,
      })
      .then((res) => {
        setLoading(false);
        console.log("Order Data :", res);
        if (res.success) {
          getCartData();

          setCheckoutSuccess(true);
          dispatch(getCartItems());
        } else {
          createAlert("error", res.message);
        }
      });
  };

  return (
    <div>
      <PageHeader title="Checkout" />
      <PageBreadcrumb
        items={[
          { link: "/", title: "Home" },
          { link: "/cart", title: "Cart" },
          { title: "Checkout" },
        ]}
      />
      {/*  */}

      {/* ---- Success ---- */}

      <AnimatePresence>
        {checkoutSuccess ? (
          <motion.div
            transition={{ duration: 0.2 }}
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0 }}
          >
            <div className="success-dialouge mt-5">
              <div className="bg-green-100 p-4 rounded text-center">
                {/* <img
                src="https://img.icons8.com/cotton/64/000000/checked--v3.png"
                alt=""
                width={50}
              /> */}
                <Player
                  // style={{ height: "150px", width: "150px" }}
                  className="h-20"
                  autoplay
                  loop={true}
                  speed="1"
                  src="https://assets9.lottiefiles.com/packages/lf20_s2lryxtd.json"
                />

                <h5 className="mt-4 mb-3 font-bold text-green-900 text-lg text-center">
                  Thanks! Your order has been placed successfully.
                </h5>
              </div>
            </div>
          </motion.div>
        ) : (
          <Spin spinning={loading} indicator={antIcon}>
            <Row className="mt-5" justify="space-between" gutter={24}>
              {/* {productWithoutCod &&
                productWithoutCod.map((e) => {
                  return (
                    <Col span={24}>
                      <div className="p-4 bg-yellow-100 mt-4 rounded mb-10">
                        <p className="text-yellow-900">
                          Your cart contais items which accept cash on delivery
                          only, you can remove Following items from cart or make
                          payment through cash on delivery.
                        </p>
                        <ul className="list-none text-left mt-3">
                          {productWithoutCod.map((e) => {
                            return <li className="font-bold"> {e.product} </li>;
                          })}
                        </ul>
                      </div>
                    </Col>
                  );
                })} */}

              <Col lg={12} xs={24}>
                <h4 className="text-lg mb-5"> Select your address </h4>

                <Row
                  gutter={24}
                  className="flex justify-between sm:justify-start"
                >
                  {addresses.length ? (
                    addresses.map((e) => {
                      return (
                        <Col md={12} xs={23} key={e.id}>
                          <div
                            onClick={() => {
                              setSelectedAddress(e.id);
                            }}
                            className={`address-box mb-5 p-5 border border-solid border-slate-200 rounded-sm  transition1 cursor-pointer ${
                              selectedAddress === e.id ? "bg-slate-200" : ""
                            } `}
                          >
                            <p className="text-left font-semibold mb-3">
                              {e.address_title}
                            </p>
                            <p className="mb-0">{e.first_name}.</p>
                            <p className="mb-0">{e.phone_no}</p>
                            <p className="mb-0">{e.address_line_1}</p>
                            <p className="mb-0">{e.address_line_2}</p>
                            <p className="mb-0">
                              {e.city}, {e.state}, {e.country}, {e.pincode}.
                            </p>
                          </div>
                        </Col>
                      );
                    })
                  ) : (
                    <Col span={24} className="mb-5">
                      <Alert
                        className="mb-5 w-full"
                        message="No addresses found. Please new address to proceed."
                        type="warning"
                      />
                    </Col>
                  )}
                  <Col span={24}>
                    <button
                      onClick={showModal}
                      type="submit"
                      className="w-100 mb-4 px-7 py-2 border-solid border border-blue-400 text-blue-500 font-medium text-sm leading-snug capitalize rounded hover:text-white hover:bg-blue-400 hover:shadow-lg  focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out  flex justify-center align-center"
                    >
                      <PlusOutlined className="mr-2 " /> Add New Address
                    </button>
                  </Col>
                </Row>

                <Modal
                  style={{ top: 0 }}
                  title="Add Address"
                  open={isModalVisible}
                  onOk={handleOk}
                  footer={false}
                  width={700}
                  onCancel={handleCancel}
                >
                  <AccountAddress
                    isModal={true}
                    success={() => {
                      setIsModalVisible(false);

                      getAddresses();
                    }}
                    hideList
                  />
                </Modal>
              </Col>

              <Col lg={8} xs={24}>
                {walletBalance ? (
                  <div className="p-4 mb-5 border border-solid border-slate-100">
                    <p>Available Wallet Balance</p>
                    <h4 className="font-bold tracking-tighter text-lg mt-2">
                      ₹ {walletBalance}{" "}
                    </h4>
                  </div>
                ) : (
                  ""
                )}

                {cartDetails ? (
                  <>
                    <div className="border border-solid border-slate-200 p-5 rounded">
                      <h4 className="mb-3 text-lg font-medium text-slate-500">
                        Summary
                      </h4>
                      <hr />
                      <Row className="mt-5">
                        <Col span={12} className="text-left">
                          <p className="mb-2"> Sub Total </p>
                          <p className="mb-2"> Tax Total </p>
                          <p className="mb-2"> Delivery charge </p>
                          <p className="mb-2"> Total Discount </p>
                          {/* <p className="mb-2"> Delivery Charges </p> */}
                          <h2 className="mb-2 text-lg text-slate-700">
                            <strong> Total Amount </strong>
                          </h2>
                        </Col>
                        <Col span={12}>
                          <p className="mb-2" style={{ textAlign: "right" }}>
                            ₹{" "}
                            {parseInt(cartDetails.sub_total).toLocaleString(
                              "en-IN"
                            )}
                          </p>
                          <p className="mb-2" style={{ textAlign: "right" }}>
                            ₹ {cartDetails.tax_total}
                          </p>
                          <p className="mb-2" style={{ textAlign: "right" }}>
                            ₹ {cartDetails.delivery}
                          </p>
                          <p className="mb-2" style={{ textAlign: "right" }}>
                            ₹ {cartDetails.discount_total}
                          </p>
                          {/* <p className="mb-2" style={{ textAlign: "right" }}>
                          ₹ 0.00
                        </p> */}
                          <h2
                            className="mb-2 text-lg text-slate-700 tracking-tight"
                            style={{ textAlign: "right" }}
                          >
                            <strong>
                              {" "}
                              ₹{" "}
                              {parseInt(cartDetails.total).toLocaleString(
                                "en-IN"
                              )}{" "}
                            </strong>
                          </h2>
                        </Col>
                      </Row>
                      <Alert
                        message="Delivery charges may vary"
                        showIcon
                        banner
                        type="warning"
                      />
                    </div>
                    <button
                      onClick={checkout}
                      type="submit"
                      className="w-full mt-5 px-7 py-2 bg-blue-500 text-white font-medium text-sm leading-snug capitalize rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out mb-0 flex justify-center items-center
                  disabled:bg-blue-300 disabled:cursor-not-allowed
                  "
                    >
                      Place your order <ArrowRightOutlined className="ml-3" />
                    </button>
                  </>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </Spin>
        )}
      </AnimatePresence>
    </div>
  );
}

export default Checkout;
