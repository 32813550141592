import { Alert, Button, Col, Input, Row, Select } from "antd";
import { useCallback, useEffect, useState } from "react";
import PageBreadcrumb from "../../components/PageBreadcrumb";
import PageHeader from "../../components/PageHeader";
import { api, cart } from "../../services/website.service";
// import { CartImage } from './Cart_styles';
import { ArrowRightOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { createAlert } from "../../components/Notification";
import { Player } from "@lottiefiles/react-lottie-player";
import { app_url } from "../../utilities/config";
import { useDispatch } from "react-redux";
import { getCartItems } from "../../store/cartSlice";

const { Option } = Select;

function Cart() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [cartDetails, setCartDetails] = useState();
  const [cartItems, setCartItems] = useState();

  const [productWithoutCod, setProductWithoutCod] = useState();
  const [discount, setDiscount] = useState();

  const [coupon, setCoupon] = useState();
  // const [coupon, setCoupon] = useState();

  const applyCoupon = () => {
    cart
      .checkCoupon({
        code: coupon,
      })
      .then((res) => {
        console.log(res);
        if (res.success) {
          createAlert("success", "Coupon Applied Successfully.");
          getCart();
        } else {
          createAlert("error", res.message);
        }
      });
  };

  const updateData = () => {
    cart.get().then((res) => {
      setCartDetails(res.data);
      setCartItems(res.data.cartitem);
      dispatch(getCartItems());
      console.log("Cart", res.data);
    });
  };

  const getCart = useCallback(async () => {
    await cart.get().then((res) => {
      setCartDetails(res.data);
      setCartItems(res.data.cartitem);
      console.log("Cart", res.data);
      //
      let productsWithoutCOD = [];
      let mrp_total = 0;
      let selling_total = 0;
      res.data.cartitem.forEach((e) => {
        if (e.cod === false) {
          console.log("Product without Cod", e);
          productsWithoutCOD.push(e);
        }
        mrp_total += e.mrp;
        selling_total += e.price;
      });
      setDiscount(mrp_total - selling_total);

      setProductWithoutCod(productsWithoutCOD);
    });
  }, []);

  useEffect(() => {
    getCart();
  }, [getCart]);

  const handleEdit = (index, qty) => {
    // 1. Make a shallow copy of the items
    let items = [...cartItems];
    // 2. Make a shallow copy of the item you want to mutate
    let item = { ...items[index] };
    // 3. Replace the property you're intested in
    item.quantity = qty;
    item.edit = true;
    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    items[index] = item;
    // 5. Set the state to our new copy
    setCartItems(items);

    editQuantity(item.id, qty);
  };

  const editQuantity = (id, qty) => {
    cart
      .editItem({
        item_id: id,
        quantity: qty,
      })
      .then((res) => {
        console.log(res);
        if (res.success) {
          updateData();
          dispatch(getCartItems());

          createAlert("success", "Cart updated.");
        } else {
          createAlert("error", "Error updating cart.");
        }
      });
  };

  const removeItem = (id) => {
    cart
      .removeItem({
        item_id: id,
      })
      .then((res) => {
        console.log(res);
        if (res.success) {
          createAlert("success", "Removed item from cart.");
          dispatch(getCartItems());

          getCart();
        } else {
          createAlert("error", "Error deleting item.");
        }
      });
  };

  return (
    <div>
      <PageHeader title="Shopping Cart" />
      <PageBreadcrumb
        items={[{ link: "/", title: "Home" }, { title: "Cart" }]}
      />

      {cartItems && cartItems.length > 0 ? (
        <Row className="mt-10" gutter={40}>
          <Col xs={24} lg={16} sm={24}>
            {cartItems.length > 0 ? (
              <div>
                {cartItems.map((item, index) => {
                  return (
                    <Row
                      gutter={20}
                      justify={""}
                      className="mb-10 border-b border-solid border-slate-100 pb-5"
                      key={item.id}
                    >
                      <Col
                        xs={8}
                        sm={12}
                        lg={4}
                        className="flex justify-center align-start"
                      >
                        <img
                          src={`${app_url}${item.image.image}`}
                          alt=""
                          className="border-solid border-slate-gray p-2 border rounded"
                          style={{ width: 100, height: 100 }}
                        />
                      </Col>
                      <Col
                        xs={15}
                        sm={12}
                        lg={18}
                        //  lg={20} md={12 } xs={12}
                      >
                        {/* Title */}
                        <div className="flex justify-between items-center">
                          <h2 className="text-lg font-medium mr-5">
                            <Link
                              style={{ color: "inherit" }}
                              to={`/products/view/?id=${item.product_id}`}
                            >
                              {item.product}
                            </Link>{" "}
                          </h2>
                          <h2 className="text-lg font-medium whitespace-nowrap tracking-tight">
                            ₹ {item.total.toLocaleString("en-IN")}
                          </h2>
                        </div>
                        {/* Quantity */}
                        <Row>
                          <Col span={24} className="mt-2">
                            <div className="flex items-center justify-between w-full mb-5 mt-3">
                              <div className="flex">
                                <span className="mr-3 hidden sm:flex">
                                  {" "}
                                  Quantity :{" "}
                                </span>
                                <Select
                                  defaultValue={item.quantity}
                                  onChange={(e) => {
                                    // console.log('index', index);
                                    handleEdit(index, e);
                                  }}
                                  name="qty"
                                  // style={{ width: 100 }}
                                  placeholder="Select Quantity"
                                >
                                  <Option value="1">1</Option>
                                  <Option value="2">2</Option>
                                  <Option value="3">3</Option>
                                  <Option value="4">4</Option>
                                  <Option value="5">5</Option>
                                  <Option value="10">10</Option>
                                </Select>
                              </div>
                              <div className="flex">
                                <button
                                  onClick={() => {
                                    removeItem(item.id);
                                  }}
                                  className="bg-white hidden  leading-10 ease-in transition hover:text-red-500 
                            text-red-800 font-semibold rounded text-cs  border-red-200 ml-2 d-flex align-center sm:flex justify-center items-center"
                                >
                                  <DeleteOutlined label="" className="mr-2" />{" "}
                                  Remove Item
                                </button>
                                <button
                                  onClick={() => {
                                    removeItem(item.id);
                                  }}
                                  className="bg-white flex  leading-10 ease-in transition hover:text-red-500 
                            text-red-600 border rounded-2xl  font-semibold  text-cs  border-red-200 ml-2 d-flex align-center sm:hidden  justify-center items-center"
                                >
                                  <DeleteOutlined
                                    style={{ fontSize: "25px" }}
                                    className="m-1.5"
                                  />{" "}
                                </button>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        {/* Buttons */}
                        <div className="flex justify-between"></div>
                      </Col>
                    </Row>
                  );
                })}
              </div>
            ) : (
              ""
            )}
          </Col>
          <Col xs={24} lg={8} sm={24} className="">
            {/* Apply coupon */}
            <div className="mb-4">
              <h5> Apply Coupon </h5>
              <Input
                onChange={(e) => {
                  console.log(e.target.value);
                  setCoupon(e.target.value);
                }}
                placeholder="Enter Coupon Code"
              />
              <Button className="mt-3 w-full" onClick={applyCoupon}>
                Apply Coupon
              </Button>
            </div>
            {/* Ends -- apply coupon */}

            <div className="border border-solid border-slate-200 p-5 rounded mt-8">
              <h1 className="font-semibold text-lg mb-4">Summary</h1>
              <hr />
              {cartDetails ? (
                <>
                  <Row className="mt-5">
                    <Col span={12} className="text-left">
                      <p className="mb-2"> Sub Total </p>
                      <p className="mb-2"> Tax Total </p>
                      <p className="mb-2"> Delivery charges </p>
                      <p className="mb-2"> Total Discount </p>

                      <h2 className="mb-2 text-lg">
                        <strong> Total Amount </strong>
                      </h2>
                    </Col>
                    <Col span={12}>
                      <p className="mb-2" style={{ textAlign: "right" }}>
                        ₹{" "}
                        {parseFloat(cartDetails.sub_total).toLocaleString(
                          "en-IN"
                        )}
                      </p>
                      <p className="mb-2" style={{ textAlign: "right" }}>
                        ₹ {cartDetails.tax_total.toLocaleString("en-IN")}
                      </p>{" "}
                      <p className="mb-2" style={{ textAlign: "right" }}>
                        ₹ {cartDetails.delivery.toLocaleString("en-IN")}
                      </p>
                      <p className="mb-2" style={{ textAlign: "right" }}>
                        ₹ {cartDetails.discount_total}
                      </p>
                      <h2
                        className="mb-2 text-lg tracking-tight"
                        style={{ textAlign: "right" }}
                      >
                        <strong>
                          ₹{" "}
                          {parseFloat(cartDetails.total).toLocaleString(
                            "en-IN"
                          )}
                        </strong>
                      </h2>
                    </Col>
                    <p className="text-green-700 mt-5 font-medium">
                      {" "}
                      You're saving <strong> ₹ {discount}</strong> on this
                      order.{" "}
                    </p>
                  </Row>
                </>
              ) : (
                ""
              )}
            </div>
            <button
              onClick={() => {
                navigate("/checkout");
              }}
              type="submit"
              className="w-full mt-5 px-7 py-2 bg-blue-500 text-white font-medium text-sm leading-snug 
              capitalize rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out mb-0 flex items-center justify-center"
            >
              Checkout
              <ArrowRightOutlined className="ml-3" />
            </button>
          </Col>
          <div></div>
        </Row>
      ) : (
        <div>
          <Player
            style={{ height: "350px", width: "350px" }}
            autoplay
            loop={true}
            speed="1"
            src="https://assets4.lottiefiles.com/packages/lf20_qh5z2fdq.json"
          />
          <h5 className="text-center"> Oops, It's empty! </h5>
        </div>
      )}
    </div>
  );
}

export default Cart;
