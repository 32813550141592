function AppButton(props) {
  let color = "blue-500";

  return (
    <>
      {props.outline ? (
        <button
          disabled={props.disabled}
          className="
          disabled:border-slate-200 disabled:hover:shadow-none disabled:text-slate-400 disabled:cursor-not-allowed  disabled:bg-gray-100
          flex items-center justify-center mr-2
          border border-solid border-sky-500 text-sky-500
            px-4 py-2 rounded-sm font-medium 
          transition hover:bg-sky-500 hover:text-white hover:shadow-sky-100 hover:shadow-lg focus:ring-0"
          onClick={props.onClick}
        >
          {props.icon ? <span className="mr-2 flex"> {props.icon} </span> : ""}
          {props.title}
        </button>
      ) : (
        <button
          disabled={props.disabled}
          className="
          disabled:border-slate-200 disabled:hover:shadow-none disabled:text-slate-400 disabled:cursor-not-allowed  disabled:bg-gray-100
          flex items-center justify-center mr-2
          bg-sky-500 shadow-sm shadow-sky-200 px-4 py-2 rounded-sm text-white font-medium 
          transition hover:bg-sky-600 hover:shadow-sky-100 hover:shadow-lg focus:ring-0"
          onClick={props.onClick}
        >
          {props.icon ? <span className="mr-2 flex"> {props.icon} </span> : ""}
          {props.title}
        </button>
      )}
    </>
  );
}

export default AppButton;
