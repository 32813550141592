import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Col, Form, Input, Row, Spin, Popconfirm } from "antd";
import React, { useEffect, useState } from "react";
import { createAlert } from "../../components/Notification";
import { account, address } from "../../services/website.service";
import { AnimatePresence, motion } from "framer-motion";
import FormItem from "antd/lib/form/FormItem";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function AccountAddress(props) {
  const [form] = Form.useForm();
  const formRef = React.createRef();
  const [disableForm, setDisableForm] = useState(true && !props.isModalOpen);

  const [formloading, setFormloading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [addresses, setAddresses] = useState();

  // console.log(props.isModalOpen, "this one");
  const handleFormSuccess = (values) => {
    console.log(values);
    setDisableForm(true);

    values.id = form.getFieldValue("id");

    if (values.city && values.country && values.state) {
      if (!editMode) {
        delete values.id;
        address.add(values).then((res) => {
          if (res.success) {
            createAlert("success", "Address saved successfully.");
            getAddresses();
            form.resetFields();
            props.success();
          } else {
            createAlert("error", res.message);
          }
        });
      } else
        address.update(values).then((res) => {
          if (res.success) {
            createAlert("success", "Address saved successfully.");
            getAddresses();
            setEditMode(false);
            form.resetFields();
          } else {
            createAlert("error", res.message);
          }
        });
    } else handleFormError();
  };

  const handleFormError = (values) => {
    createAlert("error", "Please fill the form properly.");
  };

  useEffect(() => {
    if (props.isModal) setDisableForm(false);
  }, []);

  useEffect(() => {
    address.get().then((res) => {
      console.log(res);
      if (res.success) {
        setAddresses(res.data);
      } else {
        createAlert("error", res.message);
      }
    });
  }, []);

  const getAddresses = () => {
    address.get().then((res) => {
      console.log(res);
      if (res.success) {
        setAddresses(res.data);
      } else {
        createAlert("error", res.message);
      }
    });
  };

  const handleDelete = (id) => {
    address.delete(id).then((res) => {
      console.log(res);
      if (res.success) {
        getAddresses();
        createAlert("success", "Address deleted successfully.");
      } else {
        createAlert("error", "Error deleting address.");
      }
    });
  };

  const initiateEdit = (params) => {
    setEditMode(true);
    setDisableForm(false);
    console.log(params);
    form.setFieldsValue({
      id: params.id,
      address_title: params.address_title,
      address_line_1: params.address_line_1,
      address_line_2: params.address_line_2,
      first_name: params.first_name,
      phone_no: params.phone_no,
      pincode: params.pincode,
      city: params.city,
      state: params.state,
      country: params.country,
    });
    console.log("id :", form.getFieldValue("id"));
  };

  const addressClear = () => {
    console.log("id :", form.getFieldValue("id"));
    console.log(Boolean(form.getFieldValue("city")));
    form.setFieldsValue({
      city: null,
      state: null,
      country: null,
    });
    console.log("id :", form.getFieldValue("id"));
  };

  const handlePinCodeChange = (value) => {
    setFormloading(true);
    account.get_address_details(value).then((res) => {
      setFormloading(false);
      console.log(res.data);
      if (res.data[0].Status !== "Success") {
        createAlert("error", "Pincode not found.");
      } else {
        form.setFieldsValue({
          city: res.data[0].PostOffice[0].District,
          state: res.data[0].PostOffice[0].Circle,
          country: res.data[0].PostOffice[0].Country,
        });

        console.log("id :", form.getFieldValue("id"));
      }
    });
  };

  return (
    <>
      <div className="flex sm:flex-row flex-col justify-between">
        <button
          onClick={() => {
            setDisableForm(false);
            setEditMode(false);
            form.resetFields();
          }}
          type="submit"
          className={`w-100 whitespace-nowrap  ${
            props.isModal ? "hidden" : null
          } px-7  bg-slate-500 text-white font-medium text-sm leading-snug capitalize rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out mb-0 self-start`}
        >
          {editMode ? "Edit address" : " Add new address"}
        </button>
      </div>
      <Spin indicator={antIcon} spinning={formloading}>
        <Form
          className={`mt-5 ${disableForm && !props.isModalOpen && "hidden"}  `}
          name="address"
          form={form}
          ref={formRef}
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={handleFormSuccess}
          onFinishFailed={handleFormError}
          autoComplete="off"
          disabled={disableForm && !props.isModalOpen}
        >
          <Row gutter={24}>
            <Col md={24} xs={24}>
              <Form.Item
                className="hide-astricks"
                label="Addresss Title"
                name="address_title"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please enter address title.",
                  },
                ]}
              >
                <Input placeholder="E.g. home, office, etc" />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                className="hide-astricks"
                label="First Name"
                name="first_name"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please enter name.",
                  },
                ]}
              >
                <Input placeholder="Enter reciever name" />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                className="hide-astricks"
                label="Phone number"
                name="phone_no"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please enter phone number.",
                  },
                ]}
              >
                <Input placeholder="Enter phone number" />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                className="hide-astricks"
                label="Address line 1"
                name="address_line_1"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please enter address line 1.",
                  },
                ]}
              >
                <Input placeholder="Enter adress line 1" />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                className="hide-astricks"
                label="Address line 2"
                name="address_line_2"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please enter address line 2.",
                  },
                ]}
              >
                <Input placeholder="Enter adress line 2" />
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item
                className="hide-astricks"
                label="Enter pincode"
                name="pincode"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please enter pincode.",
                  },
                  () => ({
                    validator(_, value) {
                      if (!value) {
                        return Promise.reject();
                      }
                      if (isNaN(value)) {
                        return Promise.reject("Pin code has to be a number.");
                      }
                      if (value.length < 6) {
                        return Promise.reject(
                          "Pin code can't be less than 5 digits"
                        );
                      }
                      if (value.length > 6) {
                        return Promise.reject(
                          "Pin code can't be more than 5 digits"
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input
                  type="number"
                  placeholder="Enter pincode"
                  className="w-full"
                  onChange={(e) => {
                    addressClear();
                    e.target.value.length > 5 &&
                      handlePinCodeChange(e.target.value);
                  }}
                  // onBlur={(e) => {
                  //   handlePinCodeChange(e.target.value);
                  // }}
                />
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item
                className="hide-astricks"
                label="City"
                name="city"
                // hasFeedback={Boolean(form.getFieldValue("city"))}

                // rules={[
                //   {
                //     required: true,
                //     // message: "Please enter city.",
                //   },
                // ]}
              >
                <Input placeholder="Enter City" disabled={true} />
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item
                className="hide-astricks"
                label="State"
                name="state"
                // hasFeedback

                // rules={[
                //   {
                //     required: true,
                //     message: "Please enter state.",
                //   },
                // ]}
              >
                <Input placeholder="Enter State" disabled={true} />
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item
                className="hide-astricks"
                label="Country"
                name="country"
                // hasFeedback
                // rules={[
                //   {
                //     required: true,
                //     message: "Please enter country.",
                //   },
                // ]}
              >
                <Input placeholder="Enter Country" disabled={true} />
              </Form.Item>
            </Col>

            <Col md={24} xs={24}>
              <Form.Item className="mb-0 mt-5 w-100">
                <div className="text-center lg:text-left w-100">
                  <button
                    type="submit"
                    className="w-100 px-7 py-2 bg-blue-500 text-white font-medium text-sm leading-snug capitalize rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out mb-0 disabled:bg-slate-400 disabled:cursor-not-allowed disabled:text-slate-300"
                    disabled={disableForm}
                  >
                    {/* {editMode ? "Edit address" : " Add new address"} */}
                    Save
                  </button>
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
      {/* Address list */}
      {props.hideList ? (
        ""
      ) : (
        <div className="addresses mt-10">
          <AnimatePresence>
            {addresses &&
              addresses.map((e) => {
                return (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    key={e.id}
                  >
                    <div className="address border border-solid border-slate-150 rounded p-5 mb-5">
                      <div className="flex justify-between align-center items-center mb-3">
                        <h4 className="text-lg mb-0 font-medium">
                          {e.address_title}
                        </h4>
                        <div className="icons text-lg">
                          <EditOutlined
                            className="mr-5 cursor-pointer"
                            onClick={() => {
                              initiateEdit(e);
                            }}
                          />
                          <Popconfirm
                            title="Are you sure to delete this task?"
                            onConfirm={() => {
                              handleDelete(e.id);
                            }}
                            okText="Yes"
                            cancelText="No"
                          >
                            <DeleteOutlined className="cursor-pointer" />
                          </Popconfirm>
                        </div>
                      </div>
                      <p>
                        {e.first_name}, {e.phone_no}.
                      </p>
                      <p>{e.address_line_1}</p>
                      <p>{e.address_line_2}</p>
                      <p>
                        {e.city}, {e.state}, {e.country}, {e.pincode}.
                      </p>
                    </div>
                  </motion.div>
                );
              })}
          </AnimatePresence>
        </div>
      )}
    </>
  );
}

export default AccountAddress;

AccountAddress.defaultProps = {
  isModal: false,
};
