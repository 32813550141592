import React, { useEffect } from "react";
import { Form, Input } from "antd";
import {
  api_forgot_password,
  api_login,
  api_verify_password,
} from "../../services/auth.service";
import { createAlert } from "../../components/Notification";
import { useNavigate, useSearchParams } from "react-router-dom";

function EmailVerified() {
  let navigate = useNavigate();
  const [queryParams, setqueryParams] = useSearchParams();

  const verifyEmail = (payload) => {
    api_verify_password(payload).then((res) => {
      console.log(res);
      if (res.data.success) {
        createAlert("success", res.data.message);
      } else {
        createAlert("error", res.data.message);
      }
    });
  };

  useEffect(() => {
    console.log(queryParams.get("email"));
    console.log(queryParams.get("code"));

    verifyEmail({
      email: queryParams.get("email"),
      otp: queryParams.get("code"),
    });
  }, []);

  return (
    <>
      <section className="">
        <div className="px-6 text-gray-800">
          <div className="container">
            <div className="mt-20">
              <div className="block text-center">
                <h1 className="text-xl font-bold mb-8 block text-center">
                  Dzone22.
                </h1>

                <h1 className="text-4xl font-extrabold mb-8 text-center">
                  Your Email Has been successfully verified.
                </h1>

                <button
                  onClick={() => {
                    navigate("/login");
                  }}
                  className="w-100 mb-3 px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                >
                  Login Now !
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default EmailVerified;
