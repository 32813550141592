import { LoadingOutlined } from "@ant-design/icons";
import { Col, Row, Spin } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CategoryCard from "../../components/CategoryCard";
import PageBreadcrumb from "../../components/PageBreadcrumb";
import { api } from "../../services/website.service";

import { app_url } from "../../utilities/config";


const categ = [
  "Electronics",
  "Groceries",
  "Cosmetics",
  "Electronics",
  "Groceries",
  "Cosmetics",
  "Electronics",
  "Groceries",
  "Cosmetics",
];

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Category = () => {
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState();

  const getData = useCallback(async () => {
    setLoading(true);

    // Fetching categories
    await api
      .fetch_product_categories()
      .then((res) => {
        setCategories(res.data.data);
        console.log(res.data.data);
      })
      .catch((err) => {
        console.error("Error while fetching categories ->", err.message);
      });
    setLoading(false);
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <>
      <div className="mb-3">
        <h1 className="text-3xl mb-3 mt-3 font-semibold">My Account</h1>
        <PageBreadcrumb
          items={[
            { link: "/", title: "Home" },
            { link: "/account", title: "Account" },
          ]}
        />
      </div>

      <Spin
        className="w-max"
        indicator={antIcon}
        spinning={loading}
        tip="Loading Products..."
      >
        <Row gutter={20}>
          {categories?.map((item, i) => (
            <Col span={24} lg={8} key={item.title}>
              <CategoryCard
                title={item.title}
                link={`/category/view/?id=${item?.id}`}
                image={`${item.image
                    ? `${app_url}${item.image}`
                    : 'https://img.icons8.com/external-wanicon-lineal-wanicon/256/075985/external-grocery-food-delivery-wanicon-lineal-wanicon.png'
                  }`}
              />
            </Col>
          ))}
        </Row>
      </Spin>
    </>
  );
};

export default Category;
