import axios from "axios";

// Remove after migration
// export const BASE_URL = "https://offersuperstar.com/api";
// export const auth_token = localStorage.getItem("token");

const httpClient = axios.create({
  baseURL: "https://backend.dzone22.com/api",
});

httpClient.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? `token ${token}` : "";
  return config;
});

export default httpClient;
