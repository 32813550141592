import {
  CloseOutlined,
  ExclamationCircleOutlined,
  EyeFilled,
  LoadingOutlined,
} from "@ant-design/icons";
import { Badge, Col, Input, Modal, Rate, Row, Spin } from "antd";
import TextArea from "antd/lib/input/TextArea";
import confirm from "antd/lib/modal/confirm";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { createAlert } from "../../components/Notification";
import { orders, review } from "../../services/website.service";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function Orders() {
  const [ordersList, setOrdersList] = useState();
  const [isQRModalVisible, setIsQRModalVisible] = useState(false);
  const [loading, setLoading] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalDetails, setModalDetails] = useState();
  // const [showReview, setShowReview] = useState(true);
  //
  //
  const [stars, setStars] = useState(2.5);
  const [reviewTitle, setReviewTitle] = useState();
  const [reviewText, setReviewText] = useState();

  const submitReview = () => {
    if (reviewText && reviewTitle) {
      review
        .add({
          order_id: modalDetails.id,
          product_id: modalDetails.product_id,
          title: reviewTitle,
          description: reviewText,
          star: stars,
        })
        .then((res) => {
          console.log(res);
          if (res.success) {
            createAlert("success", "Review added successfully.");
            // setShowReview(false);
            setIsModalVisible(false);
            orders.get().then((res) => {
              setLoading(false);
              console.log(res);
              if (res.success) {
                setOrdersList(res.data);
              }
            });
          } else {
            createAlert("error", res.message);
          }
        });
    } else {
      createAlert("error", "Please enter review details");
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  //
  useEffect(() => {
    setLoading(true);
    orders.get().then((res) => {
      setLoading(false);
      console.log(res);
      if (res.success) {
        setOrdersList(res.data);
      } else {
      }
    });
  }, []);

  const showPromiseConfirm = (id) => {
    confirm({
      title: `Are you sure you want to cancle the order ?`,
      centered: true,
      type: "error",
      width: 450,
      icon: <ExclamationCircleOutlined />,
      content: `You will not be able to revert the action.`,
      okText: "Cancle the order",
      okType: "danger",
      onOk() {
        return new Promise(async (resolve, reject) => {
          await orders.cancle(id).then((res) => {
            console.log(res);
            if (res.success) {
              resolve();
              setIsModalVisible(false);
            } else {
              reject();
            }
          });
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() {},
    });
  };

  const handleColor = (status) => {
    switch (status) {
      case "accepted":
        return "#e2e8f0";
        break;

      case "onprocess":
        return "#bae6fd";
        break;

      case "on_the_way":
        return "#fef3c7";
        break;

      case "out_for_delivery":
        return "#bbf7d0";
        break;

      case "delivered":
        return "#bbf7d0";
        break;

      case "cancelled":
        return "#fecaca";
        break;

      default:
        break;
    }
  };

  return (
    <>
      <Spin spinning={loading}>
        <h3 className="text-xl font-bold"> Manage Orders </h3>
        <div className="orders-list mt-5">
          {ordersList &&
            ordersList.map((e) => {
              return (
                <div
                  className="order p-4 shadow-md shadow-slate-100 border-solid border-slate-100 border mb-7 text-left"
                  key={e.id}
                >
                  <Row gutter={20}>
                    <Col lg={3}>
                      <div
                        style={{
                          width: "100px",
                          height: "100px",
                        }}
                        className="p-1 span border border-slate-200 border-solid mb-3 rounded overflow-hidden"
                      >
                        <img
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                          className="object-contain object-center"
                          src={`https://backend.dzone22.com${e.image.image}`}
                          alt=""
                        />
                      </div>
                    </Col>
                    <Col xs={24} lg={21}>
                      <div className="flex justify-between w-full flex-wrap sm-flex-nowrap">
                        <div className="flex flex-col">
                          <h6 className="text-slate-600 font-medium mb-0">
                            {e.product}{" "}
                            <small className="text-slate-400 mx-1">x</small>
                            {e.quantity}
                          </h6>
                          <h6 className="text-slate-500 lowercase text-xs mt-2">
                            #{e.id}{" "}
                            <span className="mx-1 text-slate-300">|</span>{" "}
                            {moment(e.timestamp).format("YYYY-MM-DD : hh:mm A")}{" "}
                            <span className="mx-1 text-slate-300">|</span>{" "}
                            {e.order_type}
                          </h6>
                        </div>
                        <div className="flex flex-col items-start sm:items-end mt-5 sm:mt-0">
                          <h6 className="text-slate-600 lowercase font-medium mb-0">
                            ₹ {parseInt(e.total).toLocaleString("en-IN")}
                          </h6>
                          <h6 className="capitalize mt-2">
                            <Badge
                              count={
                                e.status === "out_for_delivery"
                                  ? "Out For Delivery"
                                  : e.status
                              }
                              className="text-slate-900"
                              style={{
                                padding: "0 10px",
                                color: "rgb(15, 23, 42)",
                                backgroundColor: handleColor(e.status),
                              }}
                            ></Badge>
                          </h6>
                        </div>
                      </div>
                      <div>
                        <button
                          className="
                              mt-4
                              flex items-center justify-center mr-2
                             text-sky-500
                              py-2 text-xs rounded-sm font-medium 
                              transition
                              active:ring-0  
                              focus:ring-0
                              focus:outline-none
                              "
                          onClick={() => {
                            setModalDetails(e);
                            setIsModalVisible(true);
                          }}
                        >
                          <EyeFilled className="mr-2" /> View Details
                        </button>
                      </div>
                    </Col>
                  </Row>
                </div>
              );
            })}
        </div>
        <Modal
          title="Pay Now"
          visible={isQRModalVisible}
          // visible={true}
          footer={false}
          onCancel={() => setIsQRModalVisible(false)}
        >
          <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/QR_code_for_mobile_English_Wikipedia.svg/800px-QR_code_for_mobile_English_Wikipedia.svg.png" />
        </Modal>
        <Modal
          title="Order Details"
          visible={isModalVisible}
          footer={false}
          onCancel={handleCancel}
        >
          {modalDetails ? (
            <>
              <Row
                gutter={20}
                className="border-b border-dashed border-slate-200 mb-4"
              >
                <Col span={8}>
                  <span className="text-slate-400"> Order ID </span>
                </Col>
                <Col span={16}>
                  <h6 className="text-slate-600 font-medium">
                    {modalDetails.id}
                  </h6>
                </Col>
              </Row>
              <Row
                gutter={20}
                className="border-b border-dashed border-slate-200 mb-4"
              >
                <Col span={8}>
                  <span className="text-slate-400"> Order Date </span>
                </Col>
                <Col span={16}>
                  <h6 className="text-slate-600 font-medium">
                    {moment(modalDetails.timestamp).format(
                      "YYYY-MM-DD : hh:mm A"
                    )}
                  </h6>
                </Col>
              </Row>
              <Row
                gutter={20}
                className="border-b border-dashed border-slate-200 mb-4"
              >
                <Col span={8}>
                  <span className="text-slate-400"> Product Name </span>
                </Col>
                <Col span={16}>
                  <h6 className="text-slate-600 font-medium">
                    {modalDetails.product}
                  </h6>
                </Col>
              </Row>
              <Row
                gutter={20}
                className="border-b border-dashed border-slate-200 mb-4"
              >
                <Col span={8}>
                  <span className="text-slate-400"> Quantity </span>
                </Col>
                <Col span={16}>
                  <h6 className="text-slate-600 font-medium">
                    {modalDetails.quantity}
                  </h6>
                </Col>
              </Row>
              <Row
                gutter={20}
                className="border-b border-dashed border-slate-200 mb-4"
              >
                <Col span={8}>
                  <span className="text-slate-400"> Order Status </span>
                </Col>
                <Col span={16}>
                  <h6 className="text-slate-600 font-medium">
                    {modalDetails.status}
                  </h6>
                </Col>
              </Row>
              <Row
                gutter={20}
                className="border-b border-dashed border-slate-200 mb-4"
              >
                <Col span={8}>
                  <span className="text-slate-400"> Delivery OTP </span>
                </Col>
                <Col span={16}>
                  <h6 className="text-slate-600 font-medium">
                    {modalDetails.user_otp}
                  </h6>
                </Col>
              </Row>

              {/* Review */}
              {modalDetails.status === "delivered" &&
              !modalDetails.review_submitted ? (
                <>
                  <div className="review border border-slate-300 mx-2 mt-8 p-3 rounded">
                    <h5> Give your review about product. </h5>
                    <Rate
                      allowHalf
                      defaultValue={stars}
                      onChange={(e) => {
                        console.log(e);
                        setStars(e);
                      }}
                    />
                    <div className="mt-5">
                      <div className="mb-3">
                        <Input
                          placeholder="Enter review title."
                          onChange={(e) => {
                            setReviewTitle(e.target.value);
                          }}
                        />
                      </div>
                      <div>
                        <TextArea
                          placeholder="Enter your review."
                          onChange={(e) => {
                            setReviewText(e.target.value);
                          }}
                        ></TextArea>
                      </div>
                    </div>
                    <div className="mt-5">
                      <button
                        onClick={submitReview}
                        type="submit"
                        className="w-100 px-7 py-2 border-solid border border-blue-400 text-blue-500 font-medium text-sm leading-snug capitalize rounded hover:text-white hover:bg-blue-400 hover:shadow-lg  focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out mb-0 flex justify-center align-center"
                      >
                        Submit your review.
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {/* Ends -- Review */}
              {modalDetails.status === "cancelled" ||
              modalDetails.status === "delivered" ? (
                ""
              ) : (
                <Row>
                  <Col span={24}>
                    <div className="flex justify-between ">
                      <div>
                        <button
                          onClick={() => setIsQRModalVisible(true)}
                          className="w-100 mt-4 px-7 py-2 bg-blue-500 text-white font-medium text-sm leading-snug capitalize rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out mb-0 disabled:bg-slate-400 disabled:cursor-not-allowed disabled:text-slate-300"
                        >
                          {/* {editMode ? "Edit address" : " Add new address"} */}
                          Pay Now
                        </button>
                      </div>
                      <button
                        className="
                     mt-4
                     flex items-center justify-center mr-2
                     text-red-500
                     py-2 text-xs rounded-sm font-medium 
                     transition
                     active:ring-0  
                     focus:ring-0
                     focus:outline-none"
                        onClick={() => {
                          showPromiseConfirm(modalDetails.id);
                        }}
                      >
                        <CloseOutlined className="mr-2" /> Cancel Order
                      </button>
                    </div>
                  </Col>
                </Row>
              )}
            </>
          ) : (
            ""
          )}

          <Row>
            <Col> </Col>
          </Row>
        </Modal>
      </Spin>
    </>
  );
}

export default Orders;
