import React from "react";
import { useMediaQuery } from "react-responsive";

function SliderPrevArrow(props) {
  const isMobileDevice = !useMediaQuery({
    query: "(min-device-width: 550px)",
  });
  return (
    <>
      {isMobileDevice ? (
        ""
      ) : (
        <button
          type="button"
          className="
         opacity-60
         bg-slate-50
         text-slate-500 border hover:bg-slate-700 hover:text-white focus:ring-0
          font-medium rounded-full text-sm p-3 text-center inline-flex items-center  dark:hover:text-white focus:outline-none"
          onClick={props.onClick}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M19 12H6M12 5l-7 7 7 7" />
          </svg>
        </button>
      )}
    </>
  );
}

export default SliderPrevArrow;
