import React from "react";
import { Divider, Col, Table, Row } from "antd";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";

function InvoicePrint() {
  const { state } = useLocation();
  const [details, setDetails] = useState();

  const d = state;

  let componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    setDetails(d);
    // console.log("received", d);
    setTimeout(() => {
      handlePrint();
    }, 1000);
  }, []);

  //   const details = state;

  console.log(details);
  return (
    <div ref={componentRef}>
      <div style={{ padding: 20 }}>
        <Row>
          <Col>
            <h2 style={{ fontSize: 26 }}>Invoice</h2>
          </Col>
        </Row>

        <Row gutter={24} style={{ marginTop: 32 }}>
          <Col span={8}>
            <h3>DZone Online</h3>
            <div>#944/945, 4th Cross, 9th Main,</div>
            <div>Vijaya Bank Layout,</div>
            <div>Bannerghatta Road,</div>
            <div>Bangalore - 560076</div>
          </Col>
          <Col span={8} offset={8}>
            <table>
              <tbody>
                <tr>
                  <th align="left">Invoice # </th>
                  <td>: {details?.id}</td>
                </tr>
                <tr>
                  <th>Invoice Date </th>
                  <td>: {moment(details?.timestamp).format("YYYY-MM-DD")}</td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>

        <Row style={{ marginTop: 48 }}>
          <Col span={24}>
            <div>
              Bill To: <strong>{details?.address.first_name}</strong>
            </div>
            <div>
              {details?.address.address_line_1},{" "}
              {details?.address.address_line_2}
            </div>
            <div>
              {details?.address.city},{details?.address.state} -{" "}
              {details?.address.pincode}
            </div>
          </Col>
        </Row>

        <Row style={{ marginTop: 48 }}>
          <Table
            style={{ width: "100%" }}
            dataSource={[
              {
                id: details?.id,
                name: details?.product,
                price: details?.total,
                quantity: details?.quantity,
                total: details?.total,
              },
            ]}
            pagination={false}
          >
            <Table.Column title="Order ID" dataIndex="id" />
            <Table.Column title="Item" dataIndex="name" />
            <Table.Column title="Quantity" dataIndex="quantity" />
            <Table.Column title="Price" dataIndex="price" />
            <Table.Column title="Line Total" dataIndex="total" />
          </Table>
        </Row>

        <Row style={{ marginTop: 48 }}>
          <Col span={8} offset={16}>
            <table style={{ textAlign: "left" }}>
              {/* <tr>
                <th>Sub Total</th>
                <td>&emsp;Rs. {details?.sub_total}</td>
              </tr>
              <tr>
                <th>Tax Total</th>
                <td>&emsp;Rs. {details?.tax_total}</td>
              </tr> */}
              <tr>
                <td>
                  <hr />
                </td>
                <td>
                  <hr />
                </td>
              </tr>
              <tr>
                <th>Net Total</th>
                <td>
                  <strong>&emsp;Rs. {details?.total}</strong>
                </td>
              </tr>
            </table>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default InvoicePrint;
