import React, { useEffect } from "react";
import { Form, Input } from "antd";
import { api_login } from "../../services/auth.service";
import { createAlert } from "../../components/Notification";
import { useLocation, useNavigate } from "react-router-dom";

import { Capacitor } from "@capacitor/core";

function Login() {
  let navigate = useNavigate();
  const location = useLocation();

  const handleFormSuccess = (values) => {
    // alert(1);

    let payload = {};

    let device_token_anroid;

    if (Capacitor.isNativePlatform()) {
      window["plugins"].OneSignal.getDeviceState(async (state) => {
        console.log(state.userId);
        device_token_anroid = state.userId;
      });
    }

    if (localStorage.getItem("device_token")) {
      payload = {
        username: values.username,
        password: values.password,
        device_token: localStorage.getItem("device_token"),
        role: "customer",
      };
    } else if (Capacitor.isNativePlatform()) {
      payload = {
        username: values.username,
        password: values.password,
        role: "customer",
        device_token: device_token_anroid,
      };
    } else {
      payload = {
        username: values.username,
        password: values.password,
        role: "customer",
      };
    }

    api_login(payload).then((res) => {
      console.log(res);
      if (res.success) {
        createAlert("success", res.message.toLowerCase());
        localStorage.setItem("token", res.token);
        localStorage.setItem("user", JSON.stringify(res.user));
        navigate("/");
      } else {
        createAlert("error", res.message.toLowerCase());
      }
    });
  };

  useEffect(() => {
    return () => {
      window.addEventListener("popstate", function (event) {
        navigate("/");
      });
    };
  }, []);

  const handleFormError = (params) => {
    // alert(1);
  };

  return (
    <>
      <section className="h-full mt-20">
        <div className="px-6 h-full text-gray-800">
          <div className="container h-full">
            <div className="flex xl:justify-center lg:justify-between justify-center items-center flex-wrap h-full g-6">
              <div className="grow-0 hidden sm:block shrink-1 md:shrink-0 basis-auto xl:w-6/12 lg:w-6/12 md:w-9/12 mb-12 md:mb-0">
                <img
                  src="https://img.freepik.com/free-vector/mobile-login-concept-illustration_114360-83.jpg?w=2000"
                  className="w-full"
                  alt="Sample"
                />
              </div>
              <div className="xl:ml-20 xl:w-5/12 lg:w-5/12 md:w-8/12 mb-12 md:mb-0">
                <div className="flex flex-row items-center justify-center lg:justify-start">
                  <h1 className="text-4xl font-extrabold sen">
                    Login to Dzone
                  </h1>
                </div>

                <div className="flex items-center my-4 before:flex-1 before:border-t before:border-gray-300 before:mt-0.5 after:flex-1 after:border-t after:border-gray-300 after:mt-0.5">
                  <p className="text-center font-semibold mx-4 mb-0">Welcome</p>
                </div>

                <Form
                  name="basic"
                  layout="vertical"
                  initialValues={{ remember: true }}
                  onFinish={handleFormSuccess}
                  onFinishFailed={handleFormError}
                  autoComplete="off"
                >
                  <Form.Item
                    className="hide-astricks"
                    label="Mobile number"
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Please enter mobile number.",
                      },
                      {
                        min: 10,
                        message:
                          "Mobile number should contain atleast 10 characters.",
                      },
                      {
                        max: 10,
                        message: "Mobile number cannot exceed 10 characters.",
                      },
                    ]}
                  >
                    <Input type="number" placeholder="Enter Mobile Number" />
                  </Form.Item>
                  <Form.Item
                    className="hide-astricks"
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please enter password.",
                      },
                    ]}
                  >
                    <Input type="password" placeholder="Enter Password" />
                  </Form.Item>
                  <Form.Item>
                    <div className="text-center lg:text-left">
                      <button
                        type="submit"
                        className="w-100 mb-3 px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                      >
                        Login
                      </button>
                    </div>
                  </Form.Item>
                </Form>

                <div className="sm:flex justify-between items-center mb-6">
                  <p className="text-center sm:text-left text-sm font-semibold mt-2 pt-1 mb-0">
                    Don't have an account?
                    <span
                      onClick={() => {
                        navigate("/register");
                      }}
                      className="text-blue-600 hover:text-blue-700 focus:text-blue-700 transition duration-200 ease-in-out ml-1 cursor-pointer hover:cursor-pointer"
                    >
                      Register
                    </span>
                  </p>
                  <span
                    onClick={() => {
                      navigate("/forget-password");
                    }}
                    className="text-gray-800"
                  >
                    Forgot password?
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Login;
