import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import App from "./App";
// css
import "./styles/app.scss";
import "./App.css";
import "antd/dist/antd.css";

// Pages
import Login from "./pages/auth/Login";
import Home from "./pages/home/Home";
import Cart from "./pages/cart/Cart";
import ProductView from "./pages/products/ProductView";
import Register from "./pages/auth/Register";
import UserAccount from "./pages/account/UserAccount";
import Checkout from "./pages/checkout/Checkout";
import Products from "./pages/products/Products";

// Route protection
import { ProtectedRoutes } from "./utilities/protected";
import NotFound from "./pages/NotFound";
import { Provider } from "react-redux";
import { store } from "./store/store";
import Category from "./pages/category/Category";
import CategorizedProducts from "./pages/category/CategorizedProduct";
import MyOrders from "./pages/orders/MyOrders";
import InvoicePrint from "./components/InvoicePrint";
import PrivacyPolicy from "./pages/policies/PrivacyPolicy";
import ForgetPassword from "./pages/auth/ForgetPassword";
import EmailVerified from "./pages/auth/email-verified";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="login" element={<Login />}></Route>
          <Route path="forget-password" element={<ForgetPassword />}></Route>
          <Route path="verification" element={<EmailVerified />}></Route>
          <Route path="privacy-policy" element={<PrivacyPolicy />}></Route>
          <Route path="register" element={<Register />}></Route>
          <Route path="/" element={<Home />}></Route>
          <Route path="products" element={<Products />}></Route>
          <Route path="products/view" element={<ProductView />}></Route>
          <Route path="category" element={<Category />} />
          <Route path="category/view" element={<CategorizedProducts />}></Route>
          {/* Protected routes */}
          <Route element={<ProtectedRoutes />}>
            <Route path="orders" element={<MyOrders />} />
            <Route path="orders/invoice" element={<InvoicePrint />} />

            <Route path="account" element={<UserAccount />}></Route>
            <Route path="cart" element={<Cart />}></Route>
            <Route path="checkout" element={<Checkout />}></Route>
          </Route>
          <Route path="/404" element={<NotFound />} />
        </Route>
        <Route path="*" element={<Navigate replace to="/404" />} />
      </Routes>
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
