import React from "react";

const Button = ({ disableForm, children, onClick }) => {
  return (
    <button
      onClick={onClick}
      type="submit"
      className="w-100 px-7 py-2 mt-2 bg-blue-500 text-white font-medium text-sm leading-snug capitalize rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out mb-0 disabled:bg-slate-400 disabled:cursor-not-allowed disabled:text-slate-300"
      disabled={disableForm}
    >
      {children}
    </button>
  );
};

Button.defaultProps = {
  disableForm: false,
  children: <></>,
};

export default Button;
